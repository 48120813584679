<template>
  <div>
    <b-row
      v-if="Object.keys(list).length"
      class="match-height mb-2"
    >
      <b-col
        v-for="(status) in list"
        v-show="status.canBeNext"
        :key="status.value"
      >
        <b-button
          v-b-modal.modal-status-change-comment="isCommentRequired(status.value)"
          :variant="status.color"
          :disabled="loading"
          class="btn-block p-1 font-medium-3 h-100"
          @click="updateStatus(status.value)"
        >
          {{ status.label }}
        </b-button>
      </b-col>
    </b-row>

    <b-modal
      id="modal-status-change-comment"
      color="danger"
      :title="$t(`popups.order-status.comment-required.${commentType}.title`)"
      :ok-title="$t('shared.button.submit')"
      :cancel-title="$t('shared.button.cancel')"
      :ok-disabled="comment.length < 3"
      @hidden="comment = ''"
      @ok="addComment()"
    >
      <form @submit.prevent>
        <label>
          {{ $t(`popups.order-status.comment-required.${commentType}.description`) }}
        </label>
        <b-form-input
          v-model="comment"
          :placeholder="$t(`popups.order-status.comment-required.${commentType}.comment-label`)"
          required
        />
      </form>
    </b-modal>
  </div>
</template>
<script>
import { cloneNested, empty, parseRequestError } from '@/helpers/helpers'
import guard from '@/guard'
import moment from 'moment'
import {
  BButton, BCol, BFormInput, BModal, BRow, VBModal,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BModal,
    BFormInput,
  },
  directives: {
    'b-modal': VBModal,
  },
  props: {
    toggleShow: {
      type: Function,
      required: true,
    },
    order: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      comment: '',
      newStatus: '',
      commentType: 'default',
      commentTypeByStatus: {},
      statusesRequiredComment: {
        default: [
          'patient_not_taken',
          'cancelled',
        ],
        delay: [
          'on_way_to_pickup_point',
          'completed',
        ],
      },
    }
  },
  computed: {
    me() {
      return cloneNested(this.$store.getters['auth/getUser'])
    },
    list() {
      const list = {}
      Object.keys(this.flow.ROLE_PARAMEDIC)
        .forEach(key => {
          const statusData = this.getStatusData(key)
          if (statusData.canBeNext) {
            list[key] = this.getStatusData(key)
          }
        })

      return list
    },
    flow() {
      const flow = {
        ROLE_PARAMEDIC: {
          received: ['confirmed'],
          confirmed: ['on_way_to_pickup_point'],
          on_way_to_pickup_point: ['at_pickup_point'],
          at_pickup_point: [],
          patient_pick_up: ['on_way_to_delivery_point'],
          on_way_to_delivery_point: ['at_delivery_point'],
          at_delivery_point: ['patient_delivery', 'on_way_to_pickup_point'],
          patient_not_taken: ['completed'],
          patient_delivery: ['completed'],
          completed: [],
          done: [],
          cancelled: [],
        },
        ROLE_DISPATCHER: {
          received: ['cancelled'],
          confirmed: ['cancelled'],
          on_way_to_pickup_point: [],
          at_pickup_point: [],
          on_way_to_delivery_point: [],
          at_delivery_point: [],
          patient_not_taken: ['cancelled'],
          patient_delivery: [],
          completed: ['done'],
          done: [],
          cancelled: [],
        },
      }

      if (this.order.patientOnBoard) {
        flow.ROLE_PARAMEDIC.at_pickup_point.push('patient_delivery')
      } else {
        flow.ROLE_PARAMEDIC.at_pickup_point.push('patient_pick_up')
        flow.ROLE_PARAMEDIC.at_pickup_point.push('patient_not_taken')
        flow.ROLE_DISPATCHER.on_way_to_pickup_point.push('cancelled')
        flow.ROLE_DISPATCHER.at_pickup_point.push('cancelled')
      }

      if (guard.hasAtLeastOneRole(this.me.roles, ['ROLE_ADMIN'])) {
        flow.ROLE_DISPATCHER.completed.push('cancelled')
        flow.ROLE_DISPATCHER.done.push('cancelled')
      }

      return flow
    },
  },
  watch: {
    order: {
      handler(newOrder, oldOrder) {
        if (this.loading && newOrder.updatedAt !== oldOrder.updatedAt) {
          this.loading = false
        }
      },
      deep: true,
    },
  },
  methods: {
    getStatusData(status) {
      const current = this.order.status

      return {
        value: status,
        label: this.$t(`order.status.${status}-description`),
        isCurrent: current === status,
        canBeNext: this.canBeNext(status),
        color: this.selectColor(status),
      }
    },
    selectColor(status) {
      if (['cancelled'].includes(status)) {
        return 'danger'
      }

      if (['patient_not_taken'].includes(status)) {
        return 'warning'
      }

      return 'success'
    },
    canBeNext(status) {
      const current = this.order.status
      let nextStatuses = []

      if (
        guard.hasAtLeastOneRole(this.me.roles, ['ROLE_PARAMEDIC', 'ROLE_ADVANCED_DISPATCHER'])
          && !empty(this.flow.ROLE_PARAMEDIC[current])
      ) {
        nextStatuses = nextStatuses.concat(this.flow.ROLE_PARAMEDIC[current])
      }

      if (guard.hasAtLeastOneRole(this.me.roles, ['ROLE_DISPATCHER']) && !empty(this.flow.ROLE_DISPATCHER[current])) {
        nextStatuses = nextStatuses.concat(this.flow.ROLE_DISPATCHER[current])
      }

      return nextStatuses.includes(status)
    },
    updateStatus(newStatus, checkComment = true) {
      this.newStatus = newStatus
      this.commentType = this.commentTypeByStatus[newStatus]
      if (checkComment && this.isCommentRequired(newStatus)) {
        return
      }

      this.loading = true
      const statusData = {
        latitude: 0,
        longitude: 0,
        status: newStatus,
      }

      if (newStatus === 'patient_pick_up') {
        statusData.patientOnBoard = true
      }

      if (newStatus === 'patient_delivery') {
        statusData.patientOnBoard = false
      }

      this.$getLocation()
        .then(coordinates => {
          statusData.latitude = coordinates.lat
          statusData.longitude = coordinates.lng
        })
        .finally(() => {
          const payload = {
            id: this.order.id,
            data: statusData,
          }
          this.$store.dispatch('order/updateOrderStatus', payload)
            .then(() => {
              window.toast.notify.success(this.$t('order.notify.edit.success_title'))
              this.toggleShow()
            })
            .catch(err => {
              this.loading = false
              parseRequestError(err)
            })
        })
    },
    isCommentRequired(newStatus) {
      let commentRequired = this.statusesRequiredComment.default.includes(newStatus)
      let commentType = 'default'

      if (!commentRequired && newStatus === 'confirmed') {
        const diff = moment().diff(moment(this.order.updatedAt), 'minutes', true)
        if (diff > 10) {
          commentRequired = true
          commentType = 'delay'
        }
      }

      const lastStatusChange = this.getLastStatusChangeActivityCreatedAtAsMoment()
      if (lastStatusChange && !commentRequired && this.statusesRequiredComment.delay.includes(newStatus)
          && !empty(this.order.orderActivities)) {
        const diff = moment().diff(lastStatusChange, 'minutes', true)
        if (diff > 10) {
          commentRequired = true
          commentType = 'delay'
        }
      }

      this.commentTypeByStatus[newStatus] = commentType
      return commentRequired
    },
    addComment() {
      const comment = {
        author: {
          id: this.me.id,
          name: this.me.name,
          surname: this.me.surname,
        },
        comment: `${this.$t(`popups.order-status.comment-required.${this.commentType}.comment-prefix`)} "${this.$t(`order.status.${this.newStatus}`)}": ${this.comment}`,
        createdAt: moment(),
      }

      this.$store.dispatch('order/addComment', {
        id: this.order.id,
        comment,
      })
      this.comment = ''

      this.updateStatus(this.newStatus, false)
    },
    getLastStatusChangeActivityCreatedAtAsMoment() {
      let createdAt = null
      this.order.orderActivities.forEach(activity => {
        if (activity.type === 'status_change') {
          createdAt = moment(activity.createdAt)
        }
      })

      return createdAt
    },
  },
}
</script>
