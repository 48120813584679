<template>
  <b-row class="match-height">
    <b-col md="6">
      <b-card :title="$t('order.patient-info.health-condition.patient-state.title')">

        <b-form-radio-group
          v-model="order.patientInfo.healthCondition.patientState"
          @change="commitOrder"
        >
          <b-form-radio
            class="my-50 d-block"
            value="walking"
          >
            {{ $t('order.patient-info.health-condition.patient-state.walking') }}
          </b-form-radio>

          <b-form-radio
            class="my-50 d-block"
            value="sittingDown"
          >
            {{ $t('order.patient-info.health-condition.patient-state.sittingDown') }}
          </b-form-radio>

          <b-form-radio
            class="my-50 d-block"
            value="lyingDown"
          >
            {{ $t('order.patient-info.health-condition.patient-state.lyingDown') }}
          </b-form-radio>
        </b-form-radio-group>

      </b-card>
    </b-col>

    <b-col md="6">
      <b-card :title="$t('order.patient-info.health-condition.awareness.title')">

        <b-form-radio-group
          v-model="order.patientInfo.healthCondition.awareness"
          @change="commitOrder"
        >
          <b-form-radio
            class="my-50 d-block"
            :value="$t('order.patient-info.health-condition.awareness.yes')"
          >
            {{ $t('order.patient-info.health-condition.awareness.yes') }}
          </b-form-radio>

          <b-form-radio
            class="my-50 d-block"
            :value="$t('order.patient-info.health-condition.awareness.no')"
          >
            {{ $t('order.patient-info.health-condition.awareness.no') }}
          </b-form-radio>
        </b-form-radio-group>

        <label class="mt-1">
          {{ $t('order.patient-info.health-condition.awareness.other') }}
        </label>
        <b-form-input
          v-model="order.patientInfo.healthCondition.awareness"
          type="text"
          maxlength="100"
          :name="$t('order.patient-info.health-condition.awareness.other')"
          @change="commitOrder"
        />

      </b-card>
    </b-col>

    <b-col cols="12">

      <b-card :title="$t('order.patient-info.health-condition.glasgow-coma-scale.title')">

        <b-row>
          <b-col
            md="4"
            class="mb-2 mb-md-0"
          >
            <h6 class="mb-2">
              {{ $t('order.patient-info.health-condition.glasgow-coma-scale.eye-response.title') }}
            </h6>

            <b-form-radio-group
              v-model="order.patientInfo.healthCondition.glasgowComaScale.eyeResponse"
              stacked
              @change="commitOrder"
            >
              <b-form-radio
                class="my-25"
                :value="4"
              >
                {{ $t('order.patient-info.health-condition.glasgow-coma-scale.eye-response.spontaneously') }}
              </b-form-radio>

              <b-form-radio
                class="my-25"
                :value="3"
              >
                {{ $t('order.patient-info.health-condition.glasgow-coma-scale.eye-response.to-speech') }}
              </b-form-radio>

              <b-form-radio
                class="my-25"
                :value="2"
              >
                {{ $t('order.patient-info.health-condition.glasgow-coma-scale.eye-response.to-pain') }}
              </b-form-radio>

              <b-form-radio
                class="my-25"
                :value="1"
              >
                {{ $t('order.patient-info.health-condition.glasgow-coma-scale.eye-response.no-opening') }}
              </b-form-radio>
            </b-form-radio-group>

          </b-col>

          <b-col
            md="4"
            class="mb-2 mb-md-0"
          >

            <h6 class="mb-2">{{
              $t('order.patient-info.health-condition.glasgow-coma-scale.verbal-response.title')
            }}</h6>

            <b-form-radio-group
              v-model="order.patientInfo.healthCondition.glasgowComaScale.verbalResponse"
              stacked
              @change="commitOrder"
            >
              <b-form-radio
                class="my-25"
                :value="5"
              >
                {{ $t('order.patient-info.health-condition.glasgow-coma-scale.verbal-response.oriented') }}
              </b-form-radio>

              <b-form-radio
                class="my-25"
                :value="4"
              >
                {{ $t('order.patient-info.health-condition.glasgow-coma-scale.verbal-response.confused') }}
              </b-form-radio>

              <b-form-radio
                class="my-25"
                :value="3"
              >
                {{ $t('order.patient-info.health-condition.glasgow-coma-scale.verbal-response.inappropriate-words') }}
              </b-form-radio>

              <b-form-radio
                class="my-25"
                :value="2"
              >
                {{ $t('order.patient-info.health-condition.glasgow-coma-scale.verbal-response.incomprehensible-sounds') }}
              </b-form-radio>

              <b-form-radio
                class="my-25"
                :value="1"
              >
                {{ $t('order.patient-info.health-condition.glasgow-coma-scale.verbal-response.no-response') }}
              </b-form-radio>
            </b-form-radio-group>

          </b-col>

          <b-col
            md="4"
            class="mb-2 mb-md-0"
          >

            <h6 class="mb-2">{{
              $t('order.patient-info.health-condition.glasgow-coma-scale.motor-response.title')
            }}</h6>

            <b-form-radio-group
              v-model="order.patientInfo.healthCondition.glasgowComaScale.motorResponse"
              stacked
              @change="commitOrder"
            >

              <b-form-radio
                class="my-25"
                :value="6"
              >
                {{ $t('order.patient-info.health-condition.glasgow-coma-scale.motor-response.obeys-commands') }}
              </b-form-radio>

              <b-form-radio
                class="my-25"
                :value="5"
              >
                {{ $t('order.patient-info.health-condition.glasgow-coma-scale.motor-response.localizes-pain') }}
              </b-form-radio>

              <b-form-radio
                class="my-25"
                :value="4"
              >
                {{ $t('order.patient-info.health-condition.glasgow-coma-scale.motor-response.normal-flexion') }}
              </b-form-radio>

              <b-form-radio
                class="my-25"
                :value="3"
              >
                {{ $t('order.patient-info.health-condition.glasgow-coma-scale.motor-response.abnormal-flexion') }}
              </b-form-radio>

              <b-form-radio
                class="my-25"
                :value="2"
              >
                {{ $t('order.patient-info.health-condition.glasgow-coma-scale.motor-response.abnormal-extension') }}
              </b-form-radio>

              <b-form-radio
                class="my-25"
                :value="1"
              >
                {{ $t('order.patient-info.health-condition.glasgow-coma-scale.motor-response.no-response') }}
              </b-form-radio>
            </b-form-radio-group>

          </b-col>

          <b-col class="font-medium-4">
            {{ $t('order.patient-info.health-condition.glasgow-coma-scale.sum') }}:
            <strong class="ml-25">
              {{
                order.patientInfo.healthCondition.glasgowComaScale.eyeResponse
                  + order.patientInfo.healthCondition.glasgowComaScale.verbalResponse
                  + order.patientInfo.healthCondition.glasgowComaScale.motorResponse
              }}
            </strong>
          </b-col>

        </b-row>

      </b-card>

    </b-col>

    <b-col md="8">

      <b-card :title="$t('order.patient-info.health-condition.respiratory-system.title')">

        <b-row>
          <b-col md="6">
            <div class="mb-1">

              <label>
                {{ $t('order.patient-info.health-condition.respiratory-system.respiratory-rate') }}
              </label>
              <b-input-group append="/ min.">
                <b-form-input
                  v-model="order.patientInfo.healthCondition.respiratorySystem.respiratoryRate"
                  maxlength="2"
                  type="number"
                  @change="commitOrder"
                />
              </b-input-group>

            </div>

            <div class="w-full">
              <label>
                {{ $t('order.patient-info.health-condition.respiratory-system.saturation') }}
              </label>
              <b-input-group append="%">
                <b-form-input
                  v-model="order.patientInfo.healthCondition.respiratorySystem.saturation"
                  type="number"
                  maxlength="2"
                  @change="commitOrder"
                />
              </b-input-group>

            </div>

            <div class="mt-1">
              <b-form-checkbox
                v-model="order.patientInfo.healthCondition.respiratorySystem.respiratoryFailure"
                class="my-25"
                value="dyspnoea"
                @change="commitOrder"
              >
                {{ $t('order.patient-info.health-condition.respiratory-system.dyspnoea') }}
              </b-form-checkbox>

              <b-form-checkbox
                v-model="order.patientInfo.healthCondition.respiratorySystem.respiratoryFailure"
                class="my-25"
                value="cyanosis"
                @change="commitOrder"
              >
                {{ $t('order.patient-info.health-condition.respiratory-system.cyanosis') }}
              </b-form-checkbox>

              <b-form-checkbox
                v-model="order.patientInfo.healthCondition.respiratorySystem.respiratoryFailure"
                class="my-25"
                value="apnea"
                @change="commitOrder"
              >
                {{ $t('order.patient-info.health-condition.respiratory-system.apnea') }}
              </b-form-checkbox>
            </div>

          </b-col>

          <b-col
            md="6"
            class="mt-2 mt-md-0"
          >

            <table>

              <thead>
                <tr>
                  <th>{{ $t('order.patient-info.health-condition.left') }}</th>
                  <th>{{ $t('order.patient-info.health-condition.right') }}</th>
                  <th />
                </tr>
              </thead>

              <tbody>

                <tr>
                  <td class="py-25">
                    <b-form-checkbox
                      v-model="order.patientInfo.healthCondition.respiratorySystem.left"
                      value="normalMurmur"
                      @change="commitOrder"
                    />
                  </td>
                  <td class="py-25">
                    <b-form-checkbox
                      v-model="order.patientInfo.healthCondition.respiratorySystem.right"
                      value="normalMurmur"
                      @change="commitOrder"
                    />
                  </td>
                  <td class="py-25">
                    {{ $t('order.patient-info.health-condition.respiratory-system.normalMurmur') }}
                  </td>
                </tr>

                <tr>
                  <td class="py-25">
                    <b-form-checkbox
                      v-model="order.patientInfo.healthCondition.respiratorySystem.left"
                      value="whirring"
                      @change="commitOrder"
                    />
                  </td>
                  <td class="py-25">
                    <b-form-checkbox
                      v-model="order.patientInfo.healthCondition.respiratorySystem.right"
                      value="whirring"
                      @change="commitOrder"
                    />
                  </td>
                  <td class="py-25">
                    {{ $t('order.patient-info.health-condition.respiratory-system.whirring') }}
                  </td>
                </tr>

                <tr>
                  <td class="py-25">
                    <b-form-checkbox
                      v-model="order.patientInfo.healthCondition.respiratorySystem.left"
                      value="wheezing"
                      @change="commitOrder"
                    />
                  </td>
                  <td class="py-25">
                    <b-form-checkbox
                      v-model="order.patientInfo.healthCondition.respiratorySystem.right"
                      value="wheezing"
                      @change="commitOrder"
                    />
                  </td>
                  <td class="py-25">
                    {{ $t('order.patient-info.health-condition.respiratory-system.wheezing') }}
                  </td>
                </tr>

                <tr>
                  <td class="py-25">
                    <b-form-checkbox
                      v-model="order.patientInfo.healthCondition.respiratorySystem.left"
                      value="crackle"
                      @change="commitOrder"
                    />
                  </td>
                  <td class="py-25">
                    <b-form-checkbox
                      v-model="order.patientInfo.healthCondition.respiratorySystem.right"
                      value="crackle"
                      @change="commitOrder"
                    />
                  </td>
                  <td class="py-25">
                    {{ $t('order.patient-info.health-condition.respiratory-system.crackle') }}
                  </td>
                </tr>

                <tr>
                  <td class="py-25">
                    <b-form-checkbox
                      v-model="order.patientInfo.healthCondition.respiratorySystem.left"
                      value="rattle"
                      @change="commitOrder"
                    />
                  </td>
                  <td class="py-25">
                    <b-form-checkbox
                      v-model="order.patientInfo.healthCondition.respiratorySystem.right"
                      value="rattle"
                      @change="commitOrder"
                    />
                  </td>
                  <td class="py-25">
                    {{ $t('order.patient-info.health-condition.respiratory-system.rattle') }}
                  </td>
                </tr>

                <tr>
                  <td class="py-25">
                    <b-form-checkbox
                      v-model="order.patientInfo.healthCondition.respiratorySystem.left"
                      value="noMurmur"
                      @change="commitOrder"
                    />
                  </td>
                  <td class="py-25">
                    <b-form-checkbox
                      v-model="order.patientInfo.healthCondition.respiratorySystem.right"
                      value="noMurmur"
                      @change="commitOrder"
                    />
                  </td>
                  <td class="py-25">
                    {{ $t('order.patient-info.health-condition.respiratory-system.noMurmur') }}
                  </td>
                </tr>

                <tr>
                  <td class="py-25">
                    <b-form-checkbox
                      v-model="order.patientInfo.healthCondition.respiratorySystem.left"
                      value="other"
                      @change="commitOrder"
                    />
                  </td>
                  <td class="py-25">
                    <b-form-checkbox
                      v-model="order.patientInfo.healthCondition.respiratorySystem.right"
                      value="other"
                      @change="commitOrder"
                    />
                  </td>
                  <td class="py-25">
                    {{ $t('order.patient-info.health-condition.respiratory-system.other') }}
                  </td>
                </tr>
              </tbody>

            </table>

          </b-col>
        </b-row>

      </b-card>

    </b-col>

    <b-col md="4">
      <b-row>
        <b-col cols="12">

          <b-card :title="$t('order.patient-info.health-condition.pressure.title')">

            <label>
              {{ $t('order.patient-info.health-condition.pressure.title') }}
            </label>
            <b-input-group append="mm/Hg">
              <b-form-input
                v-model="order.patientInfo.healthCondition.pressure"
                type="text"
                maxlength="7"
                @change="commitOrder"
              />
            </b-input-group>

          </b-card>

        </b-col>
        <b-col cols="12">

          <b-card :title="$t('order.patient-info.health-condition.pulse.title')">
            <label class="b-input--label block">
              {{ $t('order.patient-info.health-condition.pulse.title') }}
            </label>
            <b-input-group
              append="/ min."
              class="mb-1"
            >
              <b-form-input
                v-model="order.patientInfo.healthCondition.pulse.value"
                type="number"
                maxlength="3"
                @change="commitOrder"
              />
            </b-input-group>

            <b-form-radio-group
              v-model="order.patientInfo.healthCondition.pulse.regular"
              @change="commitOrder"
            >

              <b-form-radio
                :value="true"
                class="my-25"
              >
                {{ $t('order.patient-info.health-condition.pulse.regular') }}
              </b-form-radio>

              <b-form-radio
                :value="false"
                class="my-25"
              >
                {{ $t('order.patient-info.health-condition.pulse.non-regular') }}
              </b-form-radio>

            </b-form-radio-group>
          </b-card>

        </b-col>
      </b-row>
    </b-col>

    <b-col md="4">

      <b-card :title="$t('order.patient-info.health-condition.psychomotor-assessment.title')">

        <b-form-radio-group
          v-model="order.patientInfo.healthCondition.psychomotorAssessment"
          stacked
          @change="commitOrder"
        >

          <b-form-radio
            class="my-25"
            value="normal"
          >
            {{ $t('order.patient-info.health-condition.psychomotor-assessment.normal') }}
          </b-form-radio>

          <b-form-radio
            class="my-25"
            value="slowedDown"
          >
            {{ $t('order.patient-info.health-condition.psychomotor-assessment.slowedDown') }}
          </b-form-radio>

          <b-form-radio
            class="my-25"
            value="agitated"
          >
            {{ $t('order.patient-info.health-condition.psychomotor-assessment.agitated') }}
          </b-form-radio>

          <b-form-radio
            class="my-25"
            value="aggressive"
          >
            {{ $t('order.patient-info.health-condition.psychomotor-assessment.aggressive') }}
          </b-form-radio>

        </b-form-radio-group>

      </b-card>

    </b-col>

    <b-col md="8">

      <b-card :title="$t('order.patient-info.health-condition.pupils.title')">

        <b-row>
          <b-col md="6">

            <h6 class="mb-1">
              {{ $t('order.patient-info.health-condition.pupils.reaction-to-light.title') }}
            </h6>

            <table>

              <thead>
                <tr>
                  <th class="pl-25">
                    {{ $t('order.patient-info.health-condition.left') }}
                  </th>
                  <th class="pl-25">
                    {{ $t('order.patient-info.health-condition.right') }}
                  </th>
                  <th />
                </tr>
              </thead>

              <tbody>

                <tr>
                  <td class="py-25">
                    <b-form-radio
                      v-model="order.patientInfo.healthCondition.pupils.reactionToLight.left"
                      value="correct"
                      @change="commitOrder"
                    />
                  </td>
                  <td class="py-25">
                    <b-form-radio
                      v-model="order.patientInfo.healthCondition.pupils.reactionToLight.right"
                      value="correct"
                      @change="commitOrder"
                    />
                  </td>
                  <td class="py-25">
                    {{ $t('order.patient-info.health-condition.pupils.reaction-to-light.correct') }}
                  </td>
                </tr>

                <tr>
                  <td class="py-25">
                    <b-form-radio
                      v-model="order.patientInfo.healthCondition.pupils.reactionToLight.left"
                      value="slow"
                      @change="commitOrder"
                    />
                  </td>
                  <td class="py-25">
                    <b-form-radio
                      v-model="order.patientInfo.healthCondition.pupils.reactionToLight.right"
                      value="slow"
                      @change="commitOrder"
                    />
                  </td>
                  <td class="py-25">
                    {{ $t('order.patient-info.health-condition.pupils.reaction-to-light.slow') }}
                  </td>
                </tr>

                <tr>
                  <td class="py-25">
                    <b-form-radio
                      v-model="order.patientInfo.healthCondition.pupils.reactionToLight.left"
                      value="none"
                      @change="commitOrder"
                    />
                  </td>
                  <td class="py-25">
                    <b-form-radio
                      v-model="order.patientInfo.healthCondition.pupils.reactionToLight.right"
                      value="none"
                      @change="commitOrder"
                    />
                  </td>
                  <td class="py-25">
                    {{ $t('order.patient-info.health-condition.pupils.reaction-to-light.none') }}
                  </td>
                </tr>

              </tbody>
            </table>

          </b-col>

          <b-col
            md="6"
            class="mt-2 mt-md-0"
          >
            <h6 class="mb-1">
              {{ $t('order.patient-info.health-condition.pupils.width.title') }}
            </h6>

            <table>

              <thead>
                <tr>
                  <th class="pl-1">
                    {{ $t('order.patient-info.health-condition.left') }}
                  </th>
                  <th class="pl-1">
                    {{ $t('order.patient-info.health-condition.right') }}
                  </th>
                  <th />
                </tr>
              </thead>

              <tbody>

                <tr>
                  <td class="py-25">
                    <b-form-radio
                      v-model="order.patientInfo.healthCondition.pupils.width.left"
                      value="normal"
                      @change="commitOrder"
                    />
                  </td>
                  <td class="py-25">
                    <b-form-radio
                      v-model="order.patientInfo.healthCondition.pupils.width.right"
                      value="normal"
                      @change="commitOrder"
                    />
                  </td>
                  <td class="py-25">
                    {{ $t('order.patient-info.health-condition.pupils.width.normal') }}
                  </td>
                </tr>

                <tr>
                  <td class="py-25">
                    <b-form-radio
                      v-model="order.patientInfo.healthCondition.pupils.width.left"
                      value="narrow"
                      @change="commitOrder"
                    />
                  </td>
                  <td class="py-25">
                    <b-form-radio
                      v-model="order.patientInfo.healthCondition.pupils.width.right"
                      value="narrow"
                      @change="commitOrder"
                    />
                  </td>
                  <td class="py-25">
                    {{ $t('order.patient-info.health-condition.pupils.width.narrow') }}
                  </td>
                </tr>

                <tr>
                  <td class="py-25">
                    <b-form-radio
                      v-model="order.patientInfo.healthCondition.pupils.width.left"
                      value="wide"
                      @change="commitOrder"
                    />
                  </td>
                  <td class="py-25">
                    <b-form-radio
                      v-model="order.patientInfo.healthCondition.pupils.width.right"
                      value="wide"
                      @change="commitOrder"
                    />
                  </td>
                  <td class="py-25">
                    {{ $t('order.patient-info.health-condition.pupils.width.wide') }}
                  </td>
                </tr>

              </tbody>
            </table>
          </b-col>
        </b-row>

      </b-card>

    </b-col>

    <b-col md="4">
      <b-card :title="$t('order.patient-info.health-condition.paresis.title')">
        <table>

          <thead>
            <tr>
              <th>{{ $t('order.patient-info.health-condition.left') }}</th>
              <th>{{ $t('order.patient-info.health-condition.right') }}</th>
              <th />
            </tr>
          </thead>

          <tbody>

            <tr>
              <td class="py-25">
                <b-form-checkbox
                  v-model="order.patientInfo.healthCondition.paresis.upperLimb.left"
                  @change="commitOrder"
                />
              </td>
              <td class="py-25">
                <b-form-checkbox
                  v-model="order.patientInfo.healthCondition.paresis.upperLimb.right"
                  @change="commitOrder"
                />
              </td>
              <td class="py-25">
                {{ $t('order.patient-info.health-condition.paresis.upper-limb') }}
              </td>
            </tr>

            <tr>
              <td class="py-25">
                <b-form-checkbox
                  v-model="order.patientInfo.healthCondition.paresis.lowerLimb.left"
                  @change="commitOrder"
                />
              </td>
              <td class="py-25">
                <b-form-checkbox
                  v-model="order.patientInfo.healthCondition.paresis.lowerLimb.right"
                  @change="commitOrder"
                />
              </td>
              <td class="py-25">
                {{ $t('order.patient-info.health-condition.paresis.lower-limb') }}
              </td>
            </tr>

          </tbody>
        </table>
      </b-card>
    </b-col>

    <b-col md="4">
      <b-card :title="$t('order.patient-info.health-condition.intoxication.title')">

        <b-form-checkbox
          v-model="order.patientInfo.healthCondition.intoxication"
          class="my-25"
          value="alcohol"
          @change="commitOrder"
        >
          {{ $t('order.patient-info.health-condition.intoxication.alcohol') }}
        </b-form-checkbox>

        <b-form-checkbox
          v-model="order.patientInfo.healthCondition.intoxication"
          class="my-25"
          value="drugs"
          @change="commitOrder"
        >
          {{ $t('order.patient-info.health-condition.intoxication.drugs') }}
        </b-form-checkbox>

      </b-card>
    </b-col>

    <b-col md="4">
      <b-card :title="$t('order.patient-info.health-condition.other.title')">

        <div class="w-full">
          <b-form-checkbox
            v-model="order.patientInfo.healthCondition.other"
            class="my-25"
            value="pregnancy"
            @change="commitOrder"
          >
            {{ $t('order.patient-info.health-condition.other.pregnancy') }}
          </b-form-checkbox>
        </div>

        <div class="w-full">
          <b-form-checkbox
            v-model="order.patientInfo.healthCondition.other"
            class="my-25"
            value="childbirth"
            @change="commitOrder"
          >
            {{ $t('order.patient-info.health-condition.other.childbirth') }}
          </b-form-checkbox>
        </div>

        <div class="w-full">
          <b-form-checkbox
            v-model="order.patientInfo.healthCondition.other"
            class="my-25"
            value="infectiousDisease"
            @change="commitOrder"
          >
            {{ $t('order.patient-info.health-condition.other.infectiousDisease') }}
          </b-form-checkbox>
        </div>

      </b-card>
    </b-col>

    <b-col cols="12">
      <b-card :title="$t('order.patient-info.health-condition.ekg.title')">
        <b-row>
          <b-col md="4">

            <div class="w-full">
              <b-form-checkbox
                v-model="order.patientInfo.healthCondition.ekg.list"
                class="my-25"
                value="sinusRhythm"
                @change="commitOrder"
              >
                {{ $t('order.patient-info.health-condition.ekg.sinusRhythm') }}
              </b-form-checkbox>
            </div>

            <div class="w-full">
              <b-form-checkbox
                v-model="order.patientInfo.healthCondition.ekg.list"
                class="my-25"
                value="supraventricularTachycardia"
                @change="commitOrder"
              >
                {{ $t('order.patient-info.health-condition.ekg.supraventricularTachycardia') }}
              </b-form-checkbox>
            </div>

            <div class="w-full">
              <b-form-checkbox
                v-model="order.patientInfo.healthCondition.ekg.list"
                class="my-25"
                value="ventricularTachycardia"
                @change="commitOrder"
              >
                {{ $t('order.patient-info.health-condition.ekg.ventricularTachycardia') }}
              </b-form-checkbox>
            </div>

            <div class="w-full">
              <b-form-checkbox
                v-model="order.patientInfo.healthCondition.ekg.list"
                class="my-25"
                value="atrialFibrillation"
                @change="commitOrder"
              >
                {{ $t('order.patient-info.health-condition.ekg.atrialFibrillation') }}
              </b-form-checkbox>
            </div>

            <div class="w-full">
              <b-form-checkbox
                v-model="order.patientInfo.healthCondition.ekg.list"
                class="my-25"
                value="AVblock"
                @change="commitOrder"
              >
                {{ $t('order.patient-info.health-condition.ekg.AVblock') }}
              </b-form-checkbox>
            </div>

          </b-col>
          <b-col md="4">

            <div class="w-full">
              <b-form-checkbox
                v-model="order.patientInfo.healthCondition.ekg.list"
                class="my-25"
                value="sVES"
                @change="commitOrder"
              >
                {{ $t('order.patient-info.health-condition.ekg.sVES') }}
              </b-form-checkbox>
            </div>

            <div class="w-full">
              <b-form-checkbox
                v-model="order.patientInfo.healthCondition.ekg.list"
                class="my-25"
                value="VES"
                @change="commitOrder"
              >
                {{ $t('order.patient-info.health-condition.ekg.VES') }}
              </b-form-checkbox>
            </div>

            <div class="w-full">
              <b-form-checkbox
                v-model="order.patientInfo.healthCondition.ekg.list"
                class="my-25"
                value="vfvt"
                @change="commitOrder"
              >
                {{ $t('order.patient-info.health-condition.ekg.vfvt') }}
              </b-form-checkbox>
            </div>

            <div class="w-full">
              <b-form-checkbox
                v-model="order.patientInfo.healthCondition.ekg.list"
                class="my-25"
                value="assystolia"
                @change="commitOrder"
              >
                {{ $t('order.patient-info.health-condition.ekg.assystolia') }}
              </b-form-checkbox>
            </div>

            <div class="w-full">
              <b-form-checkbox
                v-model="order.patientInfo.healthCondition.ekg.list"
                class="my-25"
                value="pea"
                @change="commitOrder"
              >
                {{ $t('order.patient-info.health-condition.ekg.pea') }}
              </b-form-checkbox>
            </div>

          </b-col>
          <b-col md="4">

            <div class="w-full">
              <b-form-checkbox
                v-model="order.patientInfo.healthCondition.ekg.list"
                class="my-25"
                value="pacemaker"
                @change="commitOrder"
              >
                {{ $t('order.patient-info.health-condition.ekg.pacemaker') }}
              </b-form-checkbox>
            </div>

            <div class="w-full">
              <b-form-checkbox
                v-model="order.patientInfo.healthCondition.ekg.list"
                class="my-25"
                value="ozw"
                @change="commitOrder"
              >
                {{ $t('order.patient-info.health-condition.ekg.ozw') }}
              </b-form-checkbox>
            </div>

            <div class="w-full">
              <b-form-checkbox
                v-model="order.patientInfo.healthCondition.ekg.list"
                class="my-25"
                value="infarct"
                @change="commitOrder"
              >
                {{ $t('order.patient-info.health-condition.ekg.infarct') }}
              </b-form-checkbox>
            </div>

            <div class="w-full">
              <label>
                {{ $t('order.patient-info.health-condition.ekg.other') }}
              </label>
              <b-form-input
                v-model="order.patientInfo.healthCondition.ekg.other"
                type="text"
                maxlength="100"
                :name="$t('order.patient-info.health-condition.ekg.other')"
                @change="commitOrder"
              />
            </div>

          </b-col>
        </b-row>
      </b-card>
    </b-col>
  </b-row>
</template>
<script>

import {
  BCard, BCol, BFormCheckbox, BFormInput, BFormRadio, BFormRadioGroup, BInputGroup, BRow,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BFormInput,
    BInputGroup,
    BFormRadio,
    BFormRadioGroup,
    BFormCheckbox,
  },
  props: {
    order: {
      type: Object,
      default: () => {},
    },
    commitOrder: {
      type: Function,
      default: () => {},
    },
  },
}
</script>
