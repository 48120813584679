<template>
  <b-row class="match-height">
    <b-col md="6">

      <b-card :title="$t('order.patient-info.summary.medical-documentation.title')">

        <b-form-radio-group
          v-model="order.patientInfo.summary.medicalDocumentationIssued"
          stacked
          @change="commitOrder"
        >

          <b-form-radio
            class="my-25"
            value="notReceived"
          >
            {{ $t('order.patient-info.summary.medical-documentation.issued.notReceived') }}
          </b-form-radio>

          <b-form-radio
            class="my-25"
            value="toPatient"
          >
            {{ $t('order.patient-info.summary.medical-documentation.issued.toPatient') }}
          </b-form-radio>

          <b-form-radio
            class="my-25"
            value="toFamily"
          >
            {{ $t('order.patient-info.summary.medical-documentation.issued.toFamily') }}
          </b-form-radio>

          <b-form-radio
            class="my-25"
            value="toHospital"
          >
            {{ $t('order.patient-info.summary.medical-documentation.issued.toHospital') }}
          </b-form-radio>

          <b-form-radio
            class="my-25"
            value="other"
          >
            {{ $t('order.patient-info.summary.medical-documentation.issued.other') }}
          </b-form-radio>
        </b-form-radio-group>

      </b-card>
    </b-col>

    <b-col md="6">

      <b-card
        :title="$t('order.patient-info.summary.disinfection.title')"
      >

        <b-form-radio-group
          v-model="order.patientInfo.summary.disinfection"
          stacked
          @change="commitOrder"
        >

          <b-form-radio
            class="my-25"
            :value="null"
          >
            {{ $t('order.patient-info.summary.disinfection.not-completed') }}
          </b-form-radio>

          <b-form-radio
            class="my-25"
            value="completed"
          >
            {{ $t('order.patient-info.summary.disinfection.completed') }}
          </b-form-radio>

        </b-form-radio-group>

      </b-card>
    </b-col>

    <b-col md="6">

      <b-card
        :title="$t('order.patient-info.summary.code.title')"
      >

        <b-form-radio-group
          v-model="order.prioritySignals"
          stacked
          @change="saveTransportCode"
        >

          <b-form-radio
            class="my-25"
            :value="true"
          >
            {{ $t('order.priority-signals.true') }}
          </b-form-radio>

          <b-form-radio
            class="my-25"
            :value="false"
          >
            {{ $t('order.priority-signals.false') }}
          </b-form-radio>

        </b-form-radio-group>

      </b-card>
    </b-col>
  </b-row>
</template>
<script>

import {
  BCard, BCol, BFormRadio, BFormRadioGroup, BRow,
} from 'bootstrap-vue'
import { parseRequestError } from '@/helpers/helpers'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BFormRadio,
    BFormRadioGroup,
  },
  props: {
    order: {
      type: Object,
      default: () => {},
    },
    commitOrder: {
      type: Function,
      default: () => {},
    },
  },
  methods: {
    saveTransportCode() {
      this.commitOrder()
      this.$nextTick(() => {
        const orderData = {
          prioritySignals: this.order.prioritySignals,
        }

        const payload = { id: this.order.id, data: orderData }
        this.$store.dispatch('order/patchOrder', payload)
          .catch(err => {
            parseRequestError(err)
          })
          .finally(() => {})
      })
    },
  },
}
</script>
