<template>
  <b-row>

    <b-col>
      <b-card :title="$t('order.patient-info.title')">
        <dl
          v-for="param in getPatientInfoList"
          :key="param.label"
          class="row mb-0"
        >
          <dt class="font-bold col-4">
            {{ param.label }}
          </dt>
          <dd class="col-8">
            {{ param.value }} {{ param.postfix }}
          </dd>
        </dl>
      </b-card>
    </b-col>

  </b-row>
</template>
<script>
import { BCard, BCol, BRow } from 'bootstrap-vue'

export default {
  name: 'OrderPatientInfoShort',
  components: {
    BRow,
    BCol,
    BCard,
  },
  computed: {
    order() {
      return this.$store.getters['order/getOrderData']
    },
    getPatientInfoList() {
      const patient = this.order.patientInfo
      const list = {}

      if (patient.surname) {
        list.surname = {
          label: this.$t('order.patient-info.surname'),
          value: patient.surname,
          postfix: null,
        }
      }

      if (patient.name) {
        list.name = {
          label: this.$t('order.patient-info.name'),
          value: patient.name,
          postfix: null,
        }
      }

      if (patient.age) {
        list.age = {
          label: this.$t('order.patient-info.age'),
          value: patient.age,
          postfix: this.$t('order.patient-info.years'),
        }
      }

      if (patient.sex) {
        list.sex = {
          label: this.$t('order.patient-info.sex'),
          value: this.$t(`order.patient-info.${patient.sex}`),
          postfix: null,
        }
      }

      if (patient.weight) {
        list.weight = {
          label: this.$t('order.patient-info.weight'),
          value: patient.weight,
          postfix: 'kg',
        }
      }

      if (patient.pesel) {
        list.pesel = {
          label: this.$t('order.patient-info.pesel'),
          value: patient.pesel,
          postfix: null,
        }
      }

      return list
    },
  },
}
</script>
