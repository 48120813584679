<template>
  <b-row class="match-height">
    <b-col md="6">

      <b-card :title="$t('order.patient-info.death.death')">

        <b-form-radio-group
          v-model="order.patientInfo.death.deathOccurred"
          stacked
          @change="clearDeathData(); commitOrder()"
        >
          <b-form-radio
            class="my-25"
            value="notOccurred"
          >
            {{ $t('order.patient-info.death.occurred.notOccurred') }}
          </b-form-radio>

          <b-form-radio
            class="my-25"
            value="beforeTeamArrival"
          >
            {{ $t('order.patient-info.death.occurred.beforeTeamArrival') }}
          </b-form-radio>

          <b-form-radio
            class="my-25"
            value="duringRescue"
          >
            {{ $t('order.patient-info.death.occurred.duringRescue') }}
          </b-form-radio>

          <b-form-radio
            class="my-25"
            value="duringTransport"
          >
            {{ $t('order.patient-info.death.occurred.duringTransport') }}
          </b-form-radio>
        </b-form-radio-group>

      </b-card>

    </b-col>

    <b-col md="6">
      <b-row>
        <b-col cols="12">
          <b-card :title="$t('order.patient-info.death.time-title')">

            <label class="vs-input--label block">
              {{ $t('order.patient-info.death.time-description') }}
            </label>
            <flat-pickr
              v-model="order.patientInfo.death.time"
              class="form-control"
              :config="configDateTimePicker"
              :disabled="order.patientInfo.death.deathOccurred === 'notOccurred'"
              @on-close="commitOrder"
            />

          </b-card>
        </b-col>
        <b-col cols="12">
          <b-card :title="$t('order.patient-info.death.death-certificate.title')">

            <b-form-checkbox
              v-model="order.patientInfo.death.deathCertificateIssued"
              class="my-25"
              :disabled="order.patientInfo.death.deathOccurred === 'notOccurred'"
              @change="commitOrder"
            >
              {{ $t('order.patient-info.death.death-certificate.issued') }}
            </b-form-checkbox>

          </b-card>
        </b-col>
      </b-row>
    </b-col>

  </b-row>
</template>
<script>
import { Polish as PolishLocale } from 'flatpickr/dist/l10n/pl'
import flatPickr from 'vue-flatpickr-component'
import {
  BCard, BCol, BFormCheckbox, BFormRadio, BFormRadioGroup, BRow,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BFormRadio,
    BFormRadioGroup,
    BFormCheckbox,

    flatPickr,
  },
  props: {
    order: {
      type: Object,
      default: () => {},
    },
    commitOrder: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      configDateTimePicker: {
        locale: PolishLocale,
        enableTime: true,
        dateFormat: 'Y-m-d H:i',
        disableMobile: true,
      },
    }
  },
  methods: {
    clearDeathData() {
      this.order.patientInfo.death.time = null
      this.order.patientInfo.death.deathCertificateIssued = null
      this.$nextTick(() => this.commitOrder())
    },
  },
}
</script>
