<template>
  <div class="chats">
    <div
      v-for="comment in commentData"
      :key="comment.id"
      class="chat"
      :class="{'chat-left': comment.author.id !== me.id}"
    >
      <div class="chat-avatar">
        <b-avatar
          v-b-tooltip.hover.focus.top="`${comment.author.name} ${comment.author.surname} / ${transformDatetimeToHumanReadablePretty(comment.createdAt)}`"
          size="36"
          class="avatar-border-2 box-shadow-1"
          variant="secondary"
        >
          {{ comment.author.name.charAt(0) }}{{ comment.author.surname.charAt(0) }}
        </b-avatar>
      </div>
      <div class="chat-body">
        <div class="chat-content">
          <p>{{ comment.comment }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BAvatar, VBTooltip } from 'bootstrap-vue'
import { transformDatetimeToHumanReadablePretty } from '@/helpers/helpers'

export default {
  components: {
    BAvatar,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    commentData: {
      type: Array,
      required: true,
    },
  },
  computed: {
    me() {
      return this.$store.getters['auth/getUser']
    },
  },
  methods: {
    transformDatetimeToHumanReadablePretty,
  },
}
</script>

<style>

</style>
