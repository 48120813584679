<template>
  <b-row>
    <b-col cols="12">

      <b-card
        no-body
        footer-tag="footer"
      >

        <b-card-body>
          <b-card-title class="mb-0">
            {{ $t('order.patient-info.provided-health-services.services.title') }}
          </b-card-title>
        </b-card-body>

        <table
          v-if="order.patientInfo.providedHealthServices.services.length"
          class="table b-table"
        >
          <thead>
            <tr>
              <th class="pl-1 pr-50">
                {{ $t('order.patient-info.provided-health-services.services.name') }}
              </th>
              <th class="px-50">
                {{ $t('order.patient-info.provided-health-services.services.dose') }}
              </th>
              <th class="px-50">
                {{ $t('order.patient-info.provided-health-services.services.way') }}
              </th>
              <th class="px-50">
                {{ $t('order.patient-info.provided-health-services.services.time') }}
              </th>
              <th class="pr-1 pl-50">
                {{ $t('shared.actions') }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(service, serviceKey) in order.patientInfo.providedHealthServices.services"
              :key="serviceKey"
            >
              <td class="pl-1 pr-50">
                <b-form-input
                  v-model="service.name"
                  type="text"
                  maxlength="100"
                  :placeholder="$t('order.patient-info.provided-health-services.services.name')"
                  @input="commitOrder"
                />
              </td>
              <td class="px-50">
                <b-form-input
                  v-model="service.dose"
                  type="number"
                  maxlength="20"
                  :placeholder="$t('order.patient-info.provided-health-services.services.dose')"
                  @change="commitOrder"
                />
              </td>
              <td class="px-50">
                <b-form-input
                  v-model="service.way"
                  type="text"
                  maxlength="20"
                  :placeholder="$t('order.patient-info.provided-health-services.services.way')"
                  @change="commitOrder"
                />
              </td>
              <td class="px-50">
                <flat-pickr
                  v-model="service.time"
                  class="form-control"
                  :config="configDateTimePicker"
                  @on-close="commitOrder"
                />
              </td>
              <td class="pr-1 pl-50">
                <b-button
                  variant="flat-danger"
                  @click="removeService(serviceKey)"
                >
                  <feather-icon icon="Trash2Icon" />
                </b-button>
              </td>
            </tr>
          </tbody>
        </table>

        <template #footer>

          <div class="text-right">

            <b-button
              variant="primary"
              :disabled="!allFieldsFilled()"
              @click="addService"
            >
              {{ $t('shared.button.add') }}
            </b-button>
          </div>

        </template>

      </b-card>
    </b-col>

    <b-col cols="12">
      <b-card :title="$t('order.patient-info.provided-health-services.activities.title')">

        <b-row>

          <b-col md="3">

            <b-form-checkbox
              v-model="order.patientInfo.providedHealthServices.activities"
              class="my-25"
              value="suction"
              @change="commitOrder"
            >
              {{ $t('order.patient-info.provided-health-services.activities.suction') }}
            </b-form-checkbox>

            <b-form-checkbox
              v-model="order.patientInfo.providedHealthServices.activities"
              class="my-25"
              value="bagVentilation"
              @change="commitOrder"
            >
              {{ $t('order.patient-info.provided-health-services.activities.bagVentilation') }}
            </b-form-checkbox>

            <b-form-checkbox
              v-model="order.patientInfo.providedHealthServices.activities"
              class="my-25"
              value="ugngTube"
              @change="commitOrder"
            >
              {{ $t('order.patient-info.provided-health-services.activities.ugngTube') }}
            </b-form-checkbox>

            <b-form-checkbox
              v-model="order.patientInfo.providedHealthServices.activities"
              class="my-25"
              value="intubation"
              @change="commitOrder"
            >
              {{ $t('order.patient-info.provided-health-services.activities.intubation') }}
            </b-form-checkbox>

            <b-form-checkbox
              v-model="order.patientInfo.providedHealthServices.activities"
              class="my-25"
              value="respirator"
              @change="commitOrder"
            >
              {{ $t('order.patient-info.provided-health-services.activities.respirator') }}
            </b-form-checkbox>

            <b-form-checkbox
              v-model="order.patientInfo.providedHealthServices.activities"
              class="my-25"
              value="passiveOxygenTherapy"
              @change="commitOrder"
            >
              {{ $t('order.patient-info.provided-health-services.activities.passiveOxygenTherapy') }}
            </b-form-checkbox>

          </b-col>

          <b-col md="3">

            <b-form-checkbox
              v-model="order.patientInfo.providedHealthServices.activities"
              class="my-25"
              value="defibrillation"
              @change="commitOrder"
            >
              {{ $t('order.patient-info.provided-health-services.activities.defibrillation') }}
            </b-form-checkbox>

            <b-form-checkbox
              v-model="order.patientInfo.providedHealthServices.activities"
              class="my-25"
              value="heartMassage"
              @change="commitOrder"
            >
              {{ $t('order.patient-info.provided-health-services.activities.heartMassage') }}
            </b-form-checkbox>

            <b-form-checkbox
              v-model="order.patientInfo.providedHealthServices.activities"
              class="my-25"
              value="gastricProbe"
              @change="commitOrder"
            >
              {{ $t('order.patient-info.provided-health-services.activities.gastricProbe') }}
            </b-form-checkbox>

            <b-form-checkbox
              v-model="order.patientInfo.providedHealthServices.activities"
              class="my-25"
              value="catheterization"
              @change="commitOrder"
            >
              {{ $t('order.patient-info.provided-health-services.activities.catheterization') }}
            </b-form-checkbox>

            <b-form-checkbox
              v-model="order.patientInfo.providedHealthServices.activities"
              class="my-25"
              value="cervicalCollar"
              @change="commitOrder"
            >
              {{ $t('order.patient-info.provided-health-services.activities.cervicalCollar') }}
            </b-form-checkbox>

            <b-form-checkbox
              v-model="order.patientInfo.providedHealthServices.activities"
              class="my-25"
              value="backboard"
              @change="commitOrder"
            >
              {{ $t('order.patient-info.provided-health-services.activities.backboard') }}
            </b-form-checkbox>

          </b-col>

          <b-col md="3">

            <b-form-checkbox
              v-model="order.patientInfo.providedHealthServices.activities"
              class="my-25"
              value="immobilization"
              @change="commitOrder"
            >
              {{ $t('order.patient-info.provided-health-services.activities.immobilization') }}
            </b-form-checkbox>

            <b-form-checkbox
              v-model="order.patientInfo.providedHealthServices.activities"
              class="my-25"
              value="dressing"
              @change="commitOrder"
            >
              {{ $t('order.patient-info.provided-health-services.activities.dressing') }}
            </b-form-checkbox>

            <b-form-checkbox
              v-model="order.patientInfo.providedHealthServices.activities"
              class="my-25"
              value="cannula"
              @change="commitOrder"
            >
              {{ $t('order.patient-info.provided-health-services.activities.cannula') }}
            </b-form-checkbox>

            <b-form-checkbox
              v-model="order.patientInfo.providedHealthServices.activities"
              class="my-25"
              value="monitoring"
              @change="commitOrder"
            >
              {{ $t('order.patient-info.provided-health-services.activities.monitoring') }}
            </b-form-checkbox>

            <b-form-checkbox
              v-model="order.patientInfo.providedHealthServices.activities"
              class="my-25"
              value="externalStimulation"
              @change="commitOrder"
            >
              {{ $t('order.patient-info.provided-health-services.activities.externalStimulation') }}
            </b-form-checkbox>

          </b-col>

          <b-col md="3">

            <b-form-checkbox
              v-model="order.patientInfo.providedHealthServices.activities"
              class="my-25"
              value="immobilizationOfThePatient"
              @change="commitOrder"
            >
              {{ $t('order.patient-info.provided-health-services.activities.immobilizationOfThePatient') }}
            </b-form-checkbox>

            <b-form-checkbox
              v-model="order.patientInfo.providedHealthServices.activities"
              class="my-25"
              value="pump"
              @change="commitOrder"
            >
              {{ $t('order.patient-info.provided-health-services.activities.pump') }}
            </b-form-checkbox>

            <label>
              {{ $t('order.patient-info.provided-health-services.activities.other') }}
            </label>
            <b-form-input
              v-model="order.patientInfo.providedHealthServices.otherActivities"
              maxlength="100"
              type="text"
              @change="commitOrder"
            />

          </b-col>

        </b-row>

      </b-card>
    </b-col>
  </b-row>
</template>
<script>
import flatPickr from 'vue-flatpickr-component'
import { Polish as PolishLocale } from 'flatpickr/dist/l10n/pl'
import {
  BButton, BCard, BCardBody, BCardTitle, BCol, BFormCheckbox, BFormInput, BRow,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BCardTitle,
    BButton,
    BFormInput,
    BFormCheckbox,

    flatPickr,
  },
  props: {
    order: {
      type: Object,
      default: () => {
      },
    },
    commitOrder: {
      type: Function,
      default: () => {
      },
    },
  },
  data() {
    return {
      configDateTimePicker: {
        locale: PolishLocale,
        enableTime: true,
        dateFormat: 'Y-m-d H:i',
        disableMobile: true,
      },
    }
  },
  methods: {
    addService() {
      this.commitOrder()
      this.$store.commit('order/PUSH_PROVIDED_HEALTH_SERVICE_TO_ORDER_EDITED', {
        name: null,
        dose: null,
        way: null,
        time: null,
      })
    },
    removeService(key) {
      this.commitOrder()
      this.$store.commit('order/REMOVE_PROVIDED_HEALTH_SERVICE_TO_ORDER_EDITED', key)
    },
    allFieldsFilled() {
      let allFieldsFilled = true
      this.order.patientInfo.providedHealthServices.services.forEach(service => {
        if (!service.name) { allFieldsFilled = false }
        if (!service.dose) { allFieldsFilled = false }
        if (!service.way) { allFieldsFilled = false }
        if (!service.time) { allFieldsFilled = false }
      })
      return allFieldsFilled
    },
  },
}
</script>
