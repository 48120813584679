<template>
  <div>
    <b-row>
      <b-col md="3">

        <b-row>
          <b-col>
            <router-link
              v-for="(section, sectionName) in sections"
              :key="sectionName"
              class="btn btn-light btn-block text-left"
              :class="{'btn-primary': section.isCurrent, 'btn-dark': isDark}"
              :to="{ name: $route.name, params: {tab: 'patient-info', section: sectionName}}"
            >
              {{ section.title }}
            </router-link>

            <router-link
              class="btn btn-light btn-block text-left mt-2"
              :class="{'disabled': unsavedChanges, 'btn-dark': isDark}"
              :to="{ name: 'order-order', params: {id: order.id}}"
            >
              <feather-icon icon="PrinterIcon" />
              {{ $t('shared.button.print') }}
            </router-link>

            <div
              class="btn btn-light btn-block text-left mb-2"
              :class="{'disabled': !unsavedChanges, 'btn-dark': isDark}"
              @click="saveChanges"
            >
              <feather-icon icon="SaveIcon" />
              {{ $t('shared.button.submit') }}
            </div>
          </b-col>
        </b-row>

      </b-col>

      <b-col md="9">
        <component
          :is="section.component"
          v-for="(section, sectionName) in sections"
          v-show="currentSection === sectionName"
          :key="sectionName"
          class="md:w-4/5"
          :order="order"
          :commit-order="commitOrder"
        />
      </b-col>

    </b-row>
  </div>
</template>

<script>
import PatientData from '@/views/orders/partials/patient-info/PatientData.vue'
import HealthCondition from '@/views/orders/partials/patient-info/HealthCondition.vue'
import Diagnosis from '@/views/orders/partials/patient-info/Diagnosis.vue'
import ProvidedHealthServices from '@/views/orders/partials/patient-info/ProvidedHealthServices.vue'
import Death from '@/views/orders/partials/patient-info/Death.vue'
import TransportSummary from '@/views/orders/partials/patient-info/TransportSummary.vue'
import Attachments from '@/views/orders/partials/patient-info/Attachments.vue'
import { cloneNested, parseRequestError } from '@/helpers/helpers'
import { BCol, BRow } from 'bootstrap-vue'
import useAppConfig from '@core/app-config/useAppConfig'
import { computed } from '@vue/composition-api'

export default {
  name: 'OrderTabPatientInfo',
  components: {
    PatientData,
    HealthCondition,
    Diagnosis,
    ProvidedHealthServices,
    Death,
    TransportSummary,
    Attachments,

    BRow,
    BCol,
  },
  data() {
    return {
      order: cloneNested(this.$store.getters['order/getOrderData']),
      saveChangesTimout: null,
      unsavedChanges: false,
    }
  },
  computed: {
    orderEdited() {
      return this.$store.getters['order/getOrderData']
    },
    currentSection() {
      return this.$route.params.section ? this.$route.params.section : 'patient-data'
    },
    sections() {
      return {
        'patient-data': {
          title: this.$t('order.view.tabs.patient-info.patient-data.title'),
          component: 'PatientData',
          isCurrent: this.currentSection === 'patient-data',
          roles: {
            view: ['ROLE_DISPATCHER'],
            edit: ['ROLE_PARAMEDIC'],
          },
        },
        'health-condition': {
          title: this.$t('order.view.tabs.patient-info.health-condition.title'),
          component: 'HealthCondition',
          isCurrent: this.currentSection === 'health-condition',
          roles: {
            view: ['ROLE_DISPATCHER'],
            edit: ['ROLE_PARAMEDIC'],
          },
        },
        diagnosis: {
          title: this.$t('order.view.tabs.patient-info.diagnosis.title'),
          component: 'Diagnosis',
          isCurrent: this.currentSection === 'diagnosis',
          roles: {
            view: ['ROLE_DISPATCHER'],
            edit: ['ROLE_PARAMEDIC'],
          },
        },
        'provided-health-services': {
          title: this.$t('order.view.tabs.patient-info.provided-health-services.title'),
          component: 'ProvidedHealthServices',
          isCurrent: this.currentSection === 'provided-health-services',
          roles: {
            view: ['ROLE_DISPATCHER'],
            edit: ['ROLE_PARAMEDIC'],
          },
        },
        death: {
          title: this.$t('order.view.tabs.patient-info.death.title'),
          component: 'Death',
          isCurrent: this.currentSection === 'death',
          roles: {
            view: ['ROLE_DISPATCHER'],
            edit: ['ROLE_PARAMEDIC'],
          },
        },
        summary: {
          title: this.$t('order.view.tabs.patient-info.summary.title'),
          component: 'TransportSummary',
          isCurrent: this.currentSection === 'summary',
          roles: {
            view: ['ROLE_DISPATCHER'],
            edit: ['ROLE_PARAMEDIC'],
          },
        },
        attachments: {
          title: this.$t('order.view.tabs.patient-info.attachments.title'),
          component: 'Attachments',
          isCurrent: this.currentSection === 'attachments',
          roles: {
            view: ['ROLE_PARAMEDIC', 'ROLE_DISPATCHER'],
            edit: ['ROLE_PARAMEDIC', 'ROLE_DISPATCHER'],
          },
        },
      }
    },
  },
  watch: {
    orderEdited: {
      deep: true,
      handler(newOrder) {
        this.order = cloneNested(newOrder)
      },
    },
  },
  beforeDestroy() {
    clearTimeout(this.saveChangesTimout)
    this.saveChanges()
  },
  methods: {
    commitOrder() {
      this.$store.commit('order/SET_ORDER_EDITED', this.order)
      this.unsavedChanges = true
      this.setSaveChangesTimeout()
    },
    setSaveChangesTimeout() {
      clearTimeout(this.saveChangesTimout)
      this.saveChangesTimout = setTimeout(() => {
        this.saveChanges()
      }, 5000)
    },
    saveChanges() {
      if (!this.unsavedChanges) return

      this.unsavedChanges = false
      const payload = { id: this.order.patientInfo.id, data: this.order.patientInfo }
      this.$store.dispatch('order/updateOrderPatientData', payload)
        .then(() => {
          window.toast.notify.success(this.$t('order.notify.edit.success_title'))
        })
        .catch(err => {
          parseRequestError(err)
        })
        .finally(() => {})
    },
  },
  setup() {
    const { skin } = useAppConfig()
    const isDark = computed(() => skin.value === 'dark')
    return { skin, isDark }
  },
}
</script>
