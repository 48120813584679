<template>
  <div>
    <div
      v-for="activity in order.orderActivities"
      :key="activity.id"
    >
      <b-row

        class="match-height"
      >
        <b-col md="7">
          <b-card
            no-title
            no-footer
          >

            <div v-if="activity.type === 'status_change'">
              <h5 class="mb-50">
                {{ $t('order.activities.status-change.title') }}:
                <span class="lowercase">{{ $t(`order.status.${activity.new}`) }}</span>
              </h5>
              <p>
                {{ $t('order.activities.status-change.user') }}
                <i>{{ activity.user.surname }} {{ activity.user.name }}</i>
                {{ $t('order.activities.status-change.changed-order-status') }}
                <br>
                {{ $t('order.activities.status-change.previous-status') }}
                <i>{{ $t(`order.status.${activity.old}`) }}</i>,
                {{ $t('order.activities.status-change.new-status') }}
                <i>{{ $t(`order.status.${activity.new}`) }}</i>
              </p>
            </div>

            <div v-if="activity.type === 'team_change'">
              <h5
                v-if="activity.new && activity.old"
                class="mb-50"
              >
                {{ $t('order.activities.team-change.change-title') }}
              </h5>
              <h5
                v-else-if="activity.new"
                class="mb-50"
              >
                {{ $t('order.activities.team-change.assignment-title') }}
              </h5>
              <h5
                v-else
                class="mb-50"
              >
                {{ $t('order.activities.team-change.reassignment-title') }}
              </h5>
              <p>
                {{ $t('order.activities.team-change.user') }}
                <i>{{ activity.user.surname }} {{ activity.user.name }}</i>
                <span v-if="activity.old">
                  {{ $t('order.activities.team-change.unassigned') }}
                  <strong>{{ activity.old }}</strong>
                </span>
                <span v-if="activity.new && activity.old">
                  {{ $t('order.activities.team-change.and') }}
                </span>
                <span v-if="activity.new">
                  {{ $t('order.activities.team-change.assigned') }}
                  <strong>{{ activity.new }}</strong>
                </span>
              </p>
            </div>

            <small class="float-right mt-50">
              {{ $t('order.activities.activity-time') }}:
              {{ transformDatetimeToHumanReadablePretty(activity.createdAt) }}
            </small>
          </b-card>
        </b-col>

        <b-col md="5">
          <b-card
            no-body
            no-title
            no-footer
            class="h-100 bg-light-danger"
          >
            <iframe
              v-if="activity.latitude + activity.longitude"
              class="border-0 h-100 rounded"
              style="min-height: 150px;"
              :src="getMapUrl(activity.latitude, activity.longitude)"
              allowfullscreen
            >
              {{ activity.latitude }}, {{ activity.longitude }}
            </iframe>
            <div
              v-else
              class="h-100 mx-auto d-flex align-items-center"
            >
              {{ $t('order.activities.no-location-data') }}
            </div>
          </b-card>
        </b-col>
      </b-row>

    </div>
  </div>
</template>
<script>

import { transformDatetimeToHumanReadablePretty } from '@/helpers/helpers'
import { BCard, BCol, BRow } from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
  },
  computed: {
    order() {
      return this.$store.getters['order/getOrderData']
    },
  },
  methods: {
    transformDatetimeToHumanReadablePretty,
    getMapUrl(latitude, longitude) {
      const queryParams = {
        key: 'AIzaSyB5zbQZqenSwG_xowvr2lQ4_HfU_ZD-CQY',
        q: `${latitude},${longitude}`,
      }

      return `https://www.google.com/maps/embed/v1/place?${Object.keys(queryParams).map(key => `${key}=${queryParams[key]}`).join('&')}`
    },
  },
}
</script>
