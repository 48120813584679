<template>
  <b-row class="justify-content-center">
    <b-col
      lg="3"
      md="3"
      sm="12"
      order="1"
    >
      <b-row class="justify-content-between h-100">

        <b-col
          cols="12"
        >
          <b-card
            class="bg-success content-center cursor-pointer card-justify"
            :class="{'bg-warning': order.covidPositive}"
            @click="toggleShowOrderStatusChanger"
          >
            <div
              class="d-md-flex align-items-center card-justify w-100 text-primary text-center"
            >
              <rc-overlay
                :show="loading"
                rounded
                class="mx-auto"
                :class="{'position-absolute position-top-0 position-right-0 position-bottom-0 position-left-0': loading}"
              >
                <div
                  v-show="!loading"
                  class="w-100"
                >
                  <div class="text-white text-center font-large-1">
                    {{ $t('order.status.' + order.status) }}
                  </div>
                  <div
                    v-if="order.covidPositive"
                    class="text-white text-center font-medium-3"
                  >
                    <feather-icon icon="AlertTriangleIcon" />
                    COVID
                  </div>
                  <div
                    v-else
                    class="text-white text-center font-medium-3"
                  >
                    {{ $t('order.no-danger') }}
                  </div>
                  <div
                    v-if="order.prioritySignals"
                    class="text-white text-center font-medium-4 mt-2 font-weight-bolder"
                  >
                    <b-avatar
                      size="24"
                      variant="primary"
                      class="mr-25 mt-0"
                    >
                      <feather-icon
                        icon="LoaderIcon"
                        class="font-medium-2"
                      />
                    </b-avatar>
                    {{ $t(`order.priority-signals.${order.prioritySignals}`) }}
                  </div>
                </div>
              </rc-overlay>
            </div>
          </b-card>
        </b-col>

        <b-col v-if="order.private">
          <statistic-card-horizontal
            icon="DollarSignIcon"
            :statistic="order.amountGross + ' zł'"
            :statistic-title="$t('order.label.base-order-info.payment-method-' + order.paymentMethod)"
            color="success"
            class="card-justify"
          />
        </b-col>

      </b-row>
    </b-col>

    <b-col
      cols="12"
      md="4"
      order="3"
      order-md="2"
    >
      <b-row class="justify-content-between h-100">

        <b-col>

          <b-card
            :title="$t('order.label.transport-info.pickup-point-title')"
            class="card-justify"
          >

            <rc-overlay :show="loading">
              <div class="text-center text-md-right">
                <div>
                  <h4>
                    {{ order.pickupAddress.name }}
                  </h4>
                  <h5
                    v-if="order.pickupAddressDetails"
                    class="mt-n25"
                  >
                    {{ order.pickupAddressDetails.value }}
                  </h5>
                  <div class="mt-n50 mb-50">
                    <small
                      v-if="order.pickupAddress.alias"
                      class="text-muted text-uppercase"
                    >
                      @{{ order.pickupAddress.alias }}
                    </small>
                  </div>
                </div>
                <p class="mb-0">
                  {{ order.pickupAddress.address }}
                </p>
                <p class="mb-0">
                  {{ order.pickupAddress.zip }} {{ order.pickupAddress.city }}
                </p>
                <p
                  v-if="order.commissionedBy"
                  class="mb-0"
                >
                  {{ $t('order.label.base-order-info.commissioned-by') }}: {{ order.commissionedBy }}
                </p>
                <p class="mb-0">
                  <i>{{ order.pickupDetails }}</i>
                </p>
              </div>

              <div
                v-show="order.transportAt"
                class="text-center text-md-left mt-1"
              >
                <h4 class="mb-50">
                  {{ $t('order.label.transport-info.pickup-date-title') }}
                </h4>
                <p class="font-medium-3">
                  {{ transformDatetimeToHumanReadablePretty(order.transportAt) }}
                </p>
              </div>
            </rc-overlay>

          </b-card>
        </b-col>

      </b-row>
    </b-col>

    <b-col
      md="1"
      order="4"
      order-md="3"
    >
      <div
        v-if="order.id"
        class="d-flex align-items-center card-justify w-100 text-primary text-center"
      >
        <feather-icon
          v-if="order.returnTransport"
          icon="RepeatIcon"
          size="2x"
          class="mx-auto"
        />
        <feather-icon
          v-else
          icon="ArrowRightIcon"
          size="2x"
          class="mx-auto"
        />
      </div>
    </b-col>

    <b-col
      md="4"
      order="5"
      order-md="4"
    >
      <b-row class="justify-content-between h-100">

        <b-col>
          <b-card
            :title="$t('order.label.transport-info.delivery-point-title')"
            class="card-justify"
          >

            <rc-overlay :show="loading">
              <div class="text-center text-md-left">
                <div>
                  <h4>
                    {{ order.deliveryAddress.name }}
                  </h4>
                  <h5
                    v-if="order.deliveryAddressDetails"
                    class="mt-n25"
                  >
                    {{ order.deliveryAddressDetails.value }}
                  </h5>
                  <div class="mt-n50 mb-50">
                    <small
                      v-if="order.deliveryAddress.alias"
                      class="text-muted text-uppercase"
                    >
                      @{{ order.deliveryAddress.alias }}
                    </small>
                  </div>
                </div>
                <p class="mb-0">
                  {{ order.deliveryAddress.address }}
                </p>
                <p class="mb-0">
                  {{ order.deliveryAddress.zip }} {{ order.deliveryAddress.city }}
                </p>
                <p
                  v-if="order.agreedWith"
                  class="mb-0"
                >
                  {{ $t('order.label.base-order-info.agreed-with') }}: {{ order.agreedWith }}
                </p>
                <p class="mb-0">
                  <i>{{ order.deliveryDetails }}</i>
                </p>
              </div>
            </rc-overlay>

          </b-card>
        </b-col>

      </b-row>
    </b-col>

    <b-col
      order="2"
      order-md="5"
    >
      <order-status-changer
        v-show="showOrderStatusChanger"
        :order="order"
        :toggle-show="toggleShowOrderStatusChanger"
      />
    </b-col>
  </b-row>
</template>
<script>
import OrderStatusChanger from '@/views/orders/partials/OrderStatusChanger.vue'
import { transformDatetimeToHumanReadablePretty } from '@/helpers/helpers'
import {
  BAvatar, BCard, BCol, BRow,
} from 'bootstrap-vue'
import RcOverlay from '@/layouts/components/rc/RcOverlay.vue'
import StatisticCardHorizontal from '@core/components/statistics-cards/StatisticCardHorizontal.vue'

export default {
  name: 'OrderBasicInfo',
  components: {
    BRow,
    BCol,
    BCard,
    BAvatar,

    RcOverlay,
    StatisticCardHorizontal,

    OrderStatusChanger,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showOrderStatusChanger: false,
    }
  },
  computed: {
    order() {
      return this.$store.getters['order/getOrderData']
    },
  },
  methods: {
    transformDatetimeToHumanReadablePretty,
    toggleShowOrderStatusChanger() {
      if (!this.loading) {
        this.showOrderStatusChanger = !this.showOrderStatusChanger
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.card-justify {
  height: calc(100% - 2rem) !important;
}
</style>
