<template>
  <b-card
    class="chat-widget"
    no-body
  >
    <b-card-header>
      <div class="d-flex align-items-center">
        <b-avatar
          size="34"
          variant="light-primary"
          class="mr-50 badge-minimal"
          badge
          badge-variant="success"
        >
          {{ me.name.charAt(0) }}{{ me.surname.charAt(0) }}
        </b-avatar>
        <h5 class="mb-0">
          {{ me.name }} {{ me.surname }}
        </h5>
      </div>
      <!--      <feather-icon-->
      <!--        icon="MoreVerticalIcon"-->
      <!--        size="18"-->
      <!--      />-->
    </b-card-header>

    <section class="chat-app-window">
      <!-- User Chat Area -->
      <vue-perfect-scrollbar
        ref="refChatLogPS"
        :settings="perfectScrollbarSettings"
        class="user-chats scroll-area"
      >
        <comment
          :comment-data="comments"
        />
      </vue-perfect-scrollbar>

      <!-- Message Input -->
      <b-form
        class="chat-app-form"
        @submit.prevent="addComment"
      >
        <b-input-group class="input-group-merge form-send-message mr-1">
          <b-form-input
            v-model="newComment"
            :placeholder="$t('order.label.enter-your-comment')"
          />
        </b-input-group>
        <b-button
          variant="primary"
          type="submit"
          :disabled="!newComment"
        >
          <feather-icon icon="SendIcon" />
        </b-button>
      </b-form>
    </section>
  </b-card>
</template>
<script>
import {
  BCard, BCardHeader, BAvatar, BForm, BFormInput, BInputGroup, BButton,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Comment from '@/views/orders/partials/comments/Comment.vue'
import { transformDatetimeToHumanReadablePretty } from '@/helpers/helpers'
import moment from 'moment'

export default {
  name: 'OrderComments',
  components: {
    BCard,
    BCardHeader,
    BAvatar,
    BForm,
    BFormInput,
    BInputGroup,
    BButton,

    // SFC
    Comment,

    // 3rd party
    VuePerfectScrollbar,
  },
  props: {
    order: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      newComment: '',
      perfectScrollbarSettings: {
        maxScrollbarLength: 150,
        wheelPropagation: false,
      },
    }
  },
  computed: {
    me() {
      return this.$store.getters['auth/getUser']
    },
    comments() {
      return this.order.comments
    },
  },
  mounted() {
    this.psToBottom()
  },
  methods: {
    formatDate(date) {
      return transformDatetimeToHumanReadablePretty(date)
    },
    addComment() {
      const comment = {
        author: {
          id: this.me.id,
          name: this.me.name,
          surname: this.me.surname,
        },
        comment: this.newComment,
        createdAt: moment(),
      }

      // this.order.comments.push(comment)
      this.$store.dispatch('order/addComment', { id: this.order.id, comment }).finally(
        () => {
          this.newComment = ''
          this.$nextTick(() => {
            this.psToBottom()
          })
        },
      )
      this.$nextTick(() => {
        this.psToBottom()
      })
    },
    psToBottom() {
      const scrollEl = this.$refs.refChatLogPS.$el || this.$refs.refChatLogPS
      scrollEl.scrollTop = scrollEl.scrollHeight
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/base/pages/app-chat-list.scss";
</style>
