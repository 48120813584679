<template>
  <b-row>

    <b-col cols="12">
      <b-card :title="$t('order.view.tabs.patient-info.diagnosis.diagnosis.title')">

        <label class="vs-input--label block">
          {{ $t('order.view.tabs.patient-info.diagnosis.diagnosis.title') }}
        </label>
        <diseases-classification-autosuggest
          @disease:selected="addDiagnose"
        />

        <div
          v-for="(diagnose, key) in diagnosis"
          :key="key"
        >
          <div class="mt-50 clearfix">
            <b-button
              variant="flat-danger"
              icon="delete"
              class="float-right"
              @click="remove(key)"
            >
              <feather-icon
                icon="Trash2Icon"
              />
            </b-button>
            <div class="block mb-0">
              <strong>{{ diagnose.subCategoryCode }}</strong>: {{ diagnose.subCategory }}
            </div>
            <div>
              <small> {{ diagnose.level1 }} </small>
              <small> {{ diagnose.level2 }} </small>
              <small> {{ diagnose.level3 }} </small>
            </div>
          </div>
        </div>

      </b-card>
    </b-col>

    <b-col cols="12">
      <b-card :title="$t('order.view.tabs.patient-info.diagnosis.description.title')">

        <b-form-textarea
          v-model="order.patientInfo.diagnosis.description"
          rows="3"
          max-rows="20"
          @change="commitOrder"
        />

      </b-card>
    </b-col>
  </b-row>
</template>
<script>

import DiseasesClassificationAutosuggest from '@/views/orders/partials/DiseasesClassificationAutosuggest.vue'
import {
  BButton,
  BCard, BCol, BFormTextarea, BRow,
} from 'bootstrap-vue'

export default {
  components: {
    BFormTextarea,
    BCard,
    BRow,
    BCol,
    BButton,

    DiseasesClassificationAutosuggest,
  },
  props: {
    order: {
      type: Object,
      default: () => {},
    },
    commitOrder: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    diagnosis() {
      return this.order.patientInfo.diagnosis.diagnosis
    },
  },
  methods: {
    addDiagnose(disease) {
      this.diagnosis.push(disease)
      this.commitOrder()
    },
    remove(index) {
      this.order.patientInfo.diagnosis.diagnosis.splice(index, 1)
      this.commitOrder()
    },
  },
}
</script>
