<template>
  <div>
    <div v-if="!loading">
      <div v-if="selectTeam">
        <div v-if="teams.length">
          <label>
            {{ $t('order.team.select') }}
          </label>
          <v-select
            id="select-team"
            v-model="team"
            :clearable="false"
            :searchable="false"
            :options="teams"
            :disabled="loading"
            @input="submit(false)"
          >
            <template #option="teamOption">
              <span v-if="teamOption">
                {{ teamOption.teamName.name }} {{ teamOption.vehicle.sideNumber }} ({{ teamOption.paramedicUser.surname }} {{ teamOption.paramedicUser.name }}, {{ teamOption.driverUser.surname }} {{ teamOption.driverUser.name }})
              </span>
            </template>
            <span
              slot="no-options"
              class="text-center"
            >
              {{ $t('order.team.no-teams-warning') }}
            </span>
          </v-select>

          <b-button
            class="float-right mt-1"
            variant="primary"
            :disabled="!team || saving"
            @click="submit(false)"
          >{{
            $t('shared.button.submit')
          }}
          </b-button>
        </div>
        <div v-else>
          <b-alert
            variant="danger"
            class="p-1"
            :show.sync="!teams.length"
          >
            {{ $t('order.team.no-teams-warning') }}
          </b-alert>
        </div>
      </div>
      <div
        v-if="hasAtLeastOneRole(me.roles, ['ROLE_DISPATCHER'])"
        class="text-right mt-1"
      >
        <b-button
          v-show="order.team.id"
          variant="outline-warning"
          :disabled="saving"
          class="ml-50"
          @click="changeTeam"
        >
          {{ $t('order.team.change-team') }}
        </b-button>
        <b-button
          v-show="teamCopy.id"
          variant="outline-secondary"
          :disabled="saving"
          class="ml-50"
          @click="cancelChanging"
        >
          {{ $t('shared.button.cancel') }}
        </b-button>
        <b-button
          v-show="canIUnbindTeam"
          variant="outline-danger"
          :disabled="saving"
          class="ml-50"
          @click="unbindTeam"
        >
          {{ $t('order.team.unbind-team') }}
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import { cloneNested, parseRequestError } from '@/helpers/helpers'
import { BAlert, BButton } from 'bootstrap-vue'
import guard from '@/guard'

export default {
  components: {
    vSelect,
    BButton,
    BAlert,
  },
  props: {
    order: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      loading: false,
      saving: false,
      team: {},
      teamCopy: {},
      selectTeam: false,
    }
  },
  computed: {
    teams() {
      return this.$store.getters['team/getTeams']
    },
    me() {
      return this.$store.getters['auth/getUser']
    },
    canIUnbindTeam() {
      return this.teamCopy.id
        && !this.order.patientOnBoard
        && ['received', 'confirmed', 'on_way_to_pickup_point', 'at_pickup_point'].includes(this.order.status)
    },
  },
  mounted() {
    if (!this.order.team.id) {
      this.init()
      this.selectTeam = true
    }
  },
  methods: {
    hasAtLeastOneRole: guard.hasAtLeastOneRole,
    init() {
      this.loading = true
      this.$store.dispatch('team/fetchTeamsForAssign').then(() => {
        this.loading = false
      })
    },
    getAutosuggestSuggestionValue(suggestion) {
      const { item } = suggestion
      return item.teamName.name
    },
    shouldRenderSuggestions() {
      return true
    },
    submit(selectTeamOnEnd = false) {
      this.saving = true
      const payload = { id: this.order.id, data: { team: this.team } }
      this.$store.dispatch('order/updateOrderTeam', payload)
        .then(() => {
          this.teamCopy = {}
          window.toast.notify.success(this.$t('order.notify.edit.success_title'))
          this.selectTeam = selectTeamOnEnd
        })
        .catch(err => {
          parseRequestError(err)
        })
        .finally(() => {
          this.init()
          this.loading = false
          this.teamCopy = {}
          this.saving = false
        })
    },
    changeTeam() {
      this.init()
      this.selectTeam = true
      this.teamCopy = cloneNested(this.order.team)
      this.$store.commit('order/SET_ORDER_TEAM', {})
    },
    cancelChanging() {
      this.selectTeam = false
      this.$store.commit('order/SET_ORDER_TEAM', this.teamCopy)
      this.teamCopy = {}
    },
    unbindTeam() {
      this.team = {}
      this.submit(true)
    },
  },
}
</script>
