<template>
  <div id="order-view">
    <order-breadcrumbs
      :order="order"
      :meta="meta"
    />

    <b-tabs
      justified
      lazy
      pills
    >

      <b-tab
        v-if="hasAtLeastOneRole(me.roles, ['ROLE_DISPATCHER', 'ROLE_FINANCE_MANAGER', 'ROLE_PARAMEDIC'])"
        id="viewOrderTabBasicInfo"
        :title="this.$t('order.view.tabs.basic-info.title')"
        :active="currentTab === 'basic-info'"
        @click="$router.push(`/order/view/${order.id}/basic-info`)"
      >
        <order-basic-info :loading="loading" />

        <b-row
          v-if="!loading"
          class="match-height"
        >

          <b-col md="6">
            <order-patient-info-short />
            <order-team-card
              :loading="loading"
              :order="order"
            />
          </b-col>

          <b-col md="6">
            <order-comments :order.sync="order" />
          </b-col>

        </b-row>

        <b-row>

          <b-col
            cols="12"
            style="min-height: 500px;"
          >
            <b-row>

              <b-col cols="12">
                <b-card
                  no-body
                  no-title
                  no-footer
                >
                  <iframe
                    v-if="showMap"
                    class="border-0 h-100 rounded"
                    style="min-height: 500px;"
                    :src="getMapUrl()"
                    allowfullscreen
                  />
                </b-card>
              </b-col>

            </b-row>
          </b-col>
        </b-row>
      </b-tab>

      <b-tab
        v-if="hasAtLeastOneRole(me.roles, ['ROLE_DISPATCHER', 'ROLE_PARAMEDIC'])"
        id="viewOrderTabPatientInfo"
        :title="this.$t('order.view.tabs.patient-info.title')"
        :active="currentTab === 'patient-info'"
        @click="$router.push(`/order/view/${order.id}/patient-info`)"
      >
        <order-tab-patient-info />
      </b-tab>

      <b-tab
        v-if="hasAtLeastOneRole(me.roles, ['ROLE_DISPATCHER', 'ROLE_FINANCE_MANAGER', 'ROLE_PARAMEDIC'])"
        id="viewOrderTabActivities"
        :title="this.$t('order.view.tabs.activities.title')"
        :active="currentTab === 'activities'"
        @click="$router.push(`/order/view/${order.id}/activities`)"
      >
        <order-tab-activities />
      </b-tab>

      <b-tab
        v-if="order.status === 'done' && hasAtLeastOneRole(me.roles, ['ROLE_DISPATCHER', 'ROLE_FINANCE_MANAGER'])"
        id="viewOrderTabSummary"
        :title="this.$t('order.view.tabs.summary.title')"
        :active="currentTab === 'summary'"
        @click="$router.push(`/order/view/${order.id}/summary`)"
      >
        <order-tab-summary v-if="hasAtLeastOneRole(me.roles, ['ROLE_DISPATCHER', 'ROLE_FINANCE_MANAGER'])" />
      </b-tab>

    </b-tabs>
  </div>
</template>

<script>
import { cloneNested, parseRequestError, transformDatetimeToHumanReadablePretty } from '@/helpers/helpers'
import 'flatpickr/dist/flatpickr.min.css'
import PusherClient from '@/libs/pusher'
import OrderBasicInfo from '@/views/orders/OrderView/OrderBasicInfo.vue'
import OrderPatientInfoShort from '@/views/orders/OrderView/OrderPatientInfoShort.vue'
import OrderComments from '@/views/orders/partials/comments/OrderComments.vue'
import OrderTeamCard from '@/views/orders/OrderView/OrderTeamCard.vue'
import OrderTabPatientInfo from '@/views/orders/OrderView/OrderTabPatientInfo.vue'
import OrderTabActivities from '@/views/orders/OrderView/OrderTabActivities.vue'
import OrderTabSummary from '@/views/orders/OrderView/OrderTabSummary.vue'
import OrderBreadcrumbs from '@/views/orders/partials/OrderBreadcrumbs.vue'
import {
  BCard,
  BCol, BRow, BTab, BTabs,
} from 'bootstrap-vue'
import guard from '@/guard'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BTabs,
    BTab,

    OrderBreadcrumbs,
    OrderTabActivities,
    OrderTabSummary,
    OrderPatientInfoShort,
    OrderTeamCard,
    OrderComments,
    OrderTabPatientInfo,
    OrderBasicInfo,
  },
  data() {
    return {
      loading: true,
      showMap: false,

      order: cloneNested(this.$store.getters['order/getOrderData']),

      meta: {
        breadcrumb: [
          {
            text: 'order.orders_title',
            to: '/order/list',
          },
          {
            text: 'order.view_order',
            active: true,
          },
        ],
        pageTitle: 'order.view_order',
      },
    }
  },
  computed: {
    orderEdited() {
      return this.$store.getters['order/getOrderData']
    },
    statuses() {
      return cloneNested(this.$store.getters['order/getOrderStatuses'])
    },
    me() {
      return cloneNested(this.$store.getters['auth/getUser'])
    },
    currentTab() {
      return this.$route.params.tab ? this.$route.params.tab : 'basic-info'
    },
    tabs() {
      return {
        'basic-info': {
          title: this.$t('order.view.tabs.basic-info.title'),
          roles: ['ROLE_DISPATCHER', 'ROLE_PARAMEDIC'],
          color: 'primary',
          isCurrent: this.currentTab === 'basic-info',
        },
        'patient-info': {
          title: this.$t('order.view.tabs.patient-info.title'),
          roles: ['ROLE_DISPATCHER', 'ROLE_PARAMEDIC'],
          color: 'primary',
          isCurrent: this.currentTab === 'patient-info',
        },
        activities: {
          title: this.$t('order.view.tabs.activities.title'),
          roles: ['ROLE_DISPATCHER', 'ROLE_PARAMEDIC'],
          color: 'primary',
          isCurrent: this.currentTab === 'activities',
        },
      }
    },
  },
  watch: {
    $route(to, from) {
      if (to.params.id !== from.params.id) {
        this.initOrderData(to.params.id)
        this.bindUpdate(to.params.id)
        this.unbindUpdate(from.params.id)
      }
    },
    orderEdited: {
      deep: true,
      handler(newOrder) {
        this.order = cloneNested(newOrder)
      },
    },
  },
  created() {
    this.initOrderData(this.$route.params.id)
    this.bindUpdate(this.$route.params.id)
  },
  beforeDestroy() {
    this.$store.commit('order/CLEAR_ORDER_EDITED')
    this.unbindUpdate(this.order.id)
  },
  methods: {
    hasAtLeastOneRole: guard.hasAtLeastOneRole,
    transformDatetimeToHumanReadablePretty,
    getMapUrl() {
      const queryParams = {
        key: 'AIzaSyB5zbQZqenSwG_xowvr2lQ4_HfU_ZD-CQY',
        origin: `${this.order.pickupAddress.city} ${this.order.pickupAddress.zip}, ${this.order.pickupAddress.address}`,
        destination: `${this.order.deliveryAddress.city} ${this.order.deliveryAddress.zip}, ${this.order.deliveryAddress.address}`,
      }

      return `https://www.google.com/maps/embed/v1/directions?${Object.keys(queryParams)
        .map(key => `${key}=${queryParams[key]}`)
        .join('&')}`
    },
    initOrderData(orderId) {
      this.$store.dispatch('order/fetchOrderById', orderId)
        .then(() => {
          this.$nextTick(() => {
            this.showMap = true
          })
          this.loading = false
        })
        .catch(err => parseRequestError(err))
    },
    bindUpdate(id) {
      PusherClient.bind('update', 'order', data => {
        if (parseInt(id, 10) === data.id) {
          this.initOrderData(id)
        }
      })
    },
    unbindUpdate() {
      PusherClient.unbind('update', 'order')
    },
  },
}
</script>
