<template>
  <div class="diseases-classification-selector">

    <div>
      <v-select
        id="select-disease"
        v-model="queryString"
        :options="suggestions"
        :filterable="false"
        :get-option-label="getOptionLabel"
        :placeholder="$t('order.view.tabs.patient-info.diagnosis.search-in-diseases-classification')"
        @search="fetchResults"
        @option:selected="onSelected"
      >
        <span
          slot="selected-option"
          slot-scope="option"
        >
          {{ option.surname }} {{ option.name }}
        </span>
        <template #option="suggestion">
          <p
            v-if="suggestion.subCategoryCode"
            class="m-0"
          >
            <strong>{{ suggestion.subCategoryCode }}</strong>: {{ suggestion.subCategory }}
          </p>
          <p
            v-if="suggestion.level1"
            class="m-0"
          >
            <small> {{ suggestion.level1 }} </small>
            <small v-if="suggestion.level2"> > {{ suggestion.level2 }} </small>
            <small v-if="suggestion.level3"> > {{ suggestion.level3 }} </small>
          </p>
        </template>

        <template
          #no-options="{search}"
          class="text-center"
        >
          <span v-if="loading">
            {{ $t('shared.searching') }}
          </span>
          <span v-else-if="search.length > 2">
            {{ $t('shared.no-results') }}
          </span>
          <span v-else>
            {{ $t('shared.write-something') }}
          </span>
        </template>
      </v-select>

    </div>

  </div>
</template>

<script>
import vSelect from 'vue-select'

export default {
  components: {
    vSelect,
  },
  data() {
    return {
      queryString: '',
      results: [],
      timeout: null,
      selected: null,
      debounceMilliseconds: 400,
      shouldRenderSuggestions: (size, loading) => !loading && this.queryString.length > 2,
      suggestions: [],
      loading: false,
    }
  },
  methods: {
    fetchResults(queryString) {
      if (queryString.length < 3) return
      this.loading = true
      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        this.$store.dispatch('order/searchDisease', queryString)
          .then(res => {
            this.suggestions = []
            this.selected = null

            this.suggestions = res.data
          })
          .finally(() => {
            this.loading = false
          })
      }, this.debounceMilliseconds)
    },
    onSelected(suggestion) {
      this.$emit('disease:selected', suggestion)
      this.queryString = ''
    },
    getOptionLabel(suggestion) {
      return `${suggestion.subCategoryCode} ${suggestion.category} ${suggestion.subCategory} ${suggestion.level1} ${suggestion.level2} ${suggestion.level2}`
    },
  },
}
</script>
