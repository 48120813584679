<template>
  <div>
    <div>
      <v-select
        id="select-doctor"
        v-model="orderDoctor"
        :options="doctors"
        :reduce="doctor => doctor.id"
        :disabled="loading"
        @input="submit"
      >
        <span
          slot="selected-option"
          slot-scope="option"
        >
          {{ option.surname }} {{ option.name }}
        </span>
        <template #option="{ surname, name }">
          <span>{{ surname }} {{ name }}</span>
        </template>
        <span
          slot="no-options"
          class="text-center"
        >
          {{ $t('order.doctor.no-doctor-warning') }}
        </span>
      </v-select>
    </div>
  </div>
</template>

<script>
import vSelect from 'vue-select'
import { parseRequestError } from '@/helpers/helpers'

export default {
  components: {
    vSelect,
  },
  props: {
    order: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      loading: false,
      saving: false,
      orderDoctor: this.order.doctorUser ? this.order.doctorUser.id : null,
    }
  },
  computed: {
    doctors() {
      return this.$store.getters['user/getMedicineDoctors']
    },
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      this.loading = true
      this.$store.dispatch('user/fetchMedicineDoctors')
        .then(() => {
          this.loading = false
        })
    },
    submit() {
      this.loading = true
      this.$emit('doctor:selected', this.orderDoctor)
      const payload = { id: this.order.id, data: { doctorUser: this.orderDoctor ? this.orderDoctor : null } }
      this.$store.dispatch('order/patchOrder', payload)
        .then(() => {
          window.toast.notify.success(this.$t('order.notify.edit.success_title'))
        })
        .catch(err => {
          parseRequestError(err)
        })
        .finally(() => {
          this.init()
          this.loading = false
        })
    },

  },
}
</script>
