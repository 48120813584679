<template>
  <b-card
    :title="$t('order.team.title') + (order.team.id ? ': ' + order.team.teamName.name : '')"
  >

    <div v-if="order.team.id">
      <dl class="row mb-0">
        <dt class="font-bold col-4">
          {{ $t('order.team.label.paramedic-user') }}
        </dt>
        <dd class="col-8">
          {{ order.team.paramedicUser.surname }} {{ order.team.paramedicUser.name }}
        </dd>
      </dl>
      <dl class="row mb-0">
        <dt class="font-bold col-4">
          {{ $t('order.team.label.driver-user') }}
        </dt>
        <dd class="col-8">
          {{ order.team.driverUser.surname }} {{ order.team.driverUser.name }}
        </dd>
      </dl>
      <dl class="row mb-0">
        <dt class="font-bold col-4 mb-1">
          {{ $t('order.doctor.title') }}
          <feather-icon
            :icon="selectDoctor?'XIcon':'Edit2Icon'"
            class="ml-50 cursor-pointer text-primary"
            @click="selectDoctor = !selectDoctor"
          />
        </dt>
        <dd class="col-8">
          <span v-if="order.doctorUser && !selectDoctor">
            {{ order.doctorUser.surname }} {{ order.doctorUser.name }}
          </span>
          <order-doctor-selector
            v-if="selectDoctor"
            :order="order"
            @doctor:selected="doctorSelected"
          />
        </dd>
      </dl>
      <dl class="row mb-0">
        <dt class="font-bold col-4">
          {{ $t('order.team.label.vehicle') }}
        </dt>
        <dd class="col-8">
          {{ order.team.vehicle.sideNumber }}
        </dd>
      </dl>
    </div>

    <div
      v-if="!loading"
      v-visible-to-role="['ROLE_DISPATCHER']"
    >

      <order-team-selector :order="order" />

    </div>

  </b-card>
</template>
<script>
import OrderTeamSelector from '@/views/orders/partials/OrderTeamSelector.vue'
import OrderDoctorSelector from '@/views/orders/partials/OrderDoctorSelector.vue'
import { transformDatetimeToHumanReadablePretty } from '@/helpers/helpers'
import { BCard } from 'bootstrap-vue'

export default {
  name: 'OrderTeamCard',
  components: {
    BCard,

    OrderTeamSelector,
    OrderDoctorSelector,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    order: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      selectDoctor: false,
    }
  },
  methods: {
    transformDatetimeToHumanReadablePretty,
    doctorSelected() {
      this.selectDoctor = false
    },
  },
}
</script>
