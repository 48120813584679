<template>
  <div>
    <b-row
      class="match-height"
    >
      <b-col md="6">
        <b-card :title="$t('order.view.tabs.summary.customer')">
          <rc-overlay :show="loading">
            <div v-if="order.private">
              <p class="mt-2 font-medium-4 text-center">
                {{ $t('order.view.tabs.summary.private-order') }}
              </p>
            </div>
            <div v-else-if="!empty(order.customerAddress.customer)">
              <h4 class="mt-2">
                <b-link
                  v-if="hasAtLeastOneRole(me.roles, ['ROLE_FINANCE_MANAGER'])"
                  :to="{ name: 'customer-edit', params: { id: order.customerAddress.customer.id } }"
                >
                  {{ order.customerAddress.customer.name }}
                </b-link>
                <span v-else>
                  {{ order.customerAddress.customer.name }}
                </span>
              </h4>
              <div> {{ order.customerAddress.customer.addressLine1 }}</div>
              <div> {{ order.customerAddress.customer.zip }} {{ order.customerAddress.customer.city }}</div>
              <div
                v-if="order.customerAddress.customer.taxNumber"
                class="mt-75"
              >
                {{ $t('customer.label.tax-number') }}: {{ order.customerAddress.customer.taxNumber }}
              </div>
            </div>
          </rc-overlay>
        </b-card>
      </b-col>

      <b-col md="6">
        <rc-overlay :show="loading">
          <b-card
            v-if="hasAtLeastOneRole(me.roles, ['ROLE_FINANCE_MANAGER'])"
            class="text-center card-justify"
          >
            <b-avatar
              class="mb-2"
              :variant="balance > 0 ? 'light-success' : 'light-danger'"
              size="72"
            >
              <feather-icon
                size="21"
                icon="DollarSignIcon"
              />
            </b-avatar>
            <div class="truncate">
              <h2 class="mb-1 font-weight-bolder">
                {{ balance }} {{ $t('shared.currency-symbol') }}
              </h2>
              <span>{{ $t('order.view.tabs.summary.statistics.balance') }}</span>
            </div>
          </b-card>
        </rc-overlay>
      </b-col>

      <b-col md="6">
        <b-card :title="$t('order.view.tabs.summary.costs.title')">
          <rc-overlay :show="loading">
            <dl
              v-if="summary.realisationTimeInMinutes"
              class="row mb-25"
            >
              <dt class="col-sm-6 text-sm-right">
                {{ $t('order.view.tabs.summary.statistics.realisation-time') }}
              </dt>
              <dd class="col-sm-6">
                {{ Math.floor(summary.realisationTimeInMinutes / 60) }} {{ $t('shared.time-h') }}
                {{ summary.realisationTimeInMinutes % 60 }} {{ $t('shared.time-m') }}
              </dd>
            </dl>
            <dl
              v-if="summary.distance"
              class="row mb-1"
            >
              <dt class="col-sm-6 text-sm-right">
                {{ $t('order.view.tabs.summary.statistics.distance') }}
              </dt>
              <dd class="col-sm-6">
                {{ summary.distance }} km
              </dd>
            </dl>

            <div v-if="hasAtLeastOneRole(me.roles, ['ROLE_FINANCE_MANAGER'])">
              <dl
                v-if="summary.distanceCost"
                class="row mb-25"
              >
                <dt class="col-sm-6 text-sm-right">
                  {{ $t('order.view.tabs.summary.costs.distance-cost') }}
                </dt>
                <dd class="col-sm-6">
                  {{ formatNumber(summary.distanceCost) }} {{ $t('shared.currency-symbol') }}
                </dd>
              </dl>
              <dl
                v-if="summary.driverCost"
                class="row mb-25"
              >
                <dt class="col-sm-6 text-sm-right">
                  {{ $t('order.view.tabs.summary.costs.driver') }}
                </dt>
                <dd class="col-sm-6">
                  {{ formatNumber(summary.driverCost) }} {{ $t('shared.currency-symbol') }}
                </dd>
              </dl>
              <dl
                v-if="summary.paramedicCost"
                class="row mb-25"
              >
                <dt class="col-sm-6 text-sm-right">
                  {{ $t('order.view.tabs.summary.costs.paramedic') }}
                </dt>
                <dd class="col-sm-6">
                  {{ formatNumber(summary.paramedicCost) }} {{ $t('shared.currency-symbol') }}
                </dd>
              </dl>
              <dl
                v-if="summary.doctorCost"
                class="row mb-25"
              >
                <dt class="col-sm-6 text-sm-right">
                  {{ $t('order.view.tabs.summary.costs.doctor') }}
                </dt>
                <dd class="col-sm-6">
                  {{ formatNumber(summary.doctorCost) }} {{ $t('shared.currency-symbol') }}
                </dd>
              </dl>
              <dl
                v-if="summary.additionalCosts && !showAddAdditionalCostsForm"
                class="row mb-25"
              >
                <dt class="col-sm-6 text-sm-right">
                  {{ summary.additionalCostsComment }}
                </dt>
                <dd class="col-sm-6">
                  {{ formatNumber(summary.additionalCosts) }} {{ $t('shared.currency-symbol') }}
                </dd>
              </dl>
              <dl
                v-if="showAddAdditionalCostsForm"
                class="row mb-25"
              >
                <dt class="col-sm-6 text-sm-right">
                  {{ $t('order.view.tabs.summary.costs.additional') }}
                </dt>
                <dd class="col-sm-6">
                  <b-input-group :append="$t('shared.currency-symbol') ">
                    <b-form-input
                      v-model="summary.additionalCosts"
                      maxlength="2"
                      type="number"
                    />
                  </b-input-group>
                </dd>
              </dl>
              <dl
                v-if="showAddAdditionalCostsForm"
                class="row mb-25"
              >
                <dt class="col-sm-6 text-sm-right">
                  {{ $t('order.view.tabs.summary.costs.additional-comment') }}
                </dt>
                <dd class="col-sm-6">
                  <b-form-input
                    v-model="summary.additionalCostsComment"
                    maxlength="250"
                    type="text"
                    :placeholder="$t('order.view.tabs.summary.costs.additional-comment')"
                  />
                </dd>
              </dl>
              <div
                v-else-if="!showAddSurchargeIncomeForm && summary.id"
                class="text-center"
              >
                <b-button
                  variant="flat-primary"
                  @click="showAddAdditionalCostsForm = true"
                >
                  {{ $t('order.view.tabs.summary.costs.additional-add') }}
                </b-button>
              </div>
            </div>
          </rc-overlay>

          <template
            v-if="hasAtLeastOneRole(me.roles, ['ROLE_FINANCE_MANAGER'])"
            #footer
          >
            <dl
              v-if="summary.totalCosts"
              class="row mb-0 font-medium-3"
            >
              <dt class="col-sm-6 text-sm-right">
                {{ $t('order.view.tabs.summary.costs.total') }}
              </dt>
              <dd class="col-sm-6 font-weight-bold">
                {{ formatNumber(summary.totalCosts) }} {{ $t('shared.currency-symbol') }}
              </dd>
            </dl>
          </template>
        </b-card>
      </b-col>

      <b-col md="6">
        <b-card :title="$t('order.view.tabs.summary.incomes.title')">
          <rc-overlay :show="loading">
            <dl
              v-if="summary.realisationTimeInMinutes"
              class="row mb-25"
            >
              <dt class="col-sm-6 text-sm-right">
                {{ $t('order.view.tabs.summary.statistics.realisation-time-for-billing') }}
              </dt>
              <dd class="col-sm-6">
                {{ Math.floor(summary.realisationTimeForBillingInMinutes / 60) }} {{ $t('shared.time-h') }}
                {{ summary.realisationTimeForBillingInMinutes % 60 }} {{ $t('shared.time-m') }}
              </dd>
            </dl>
            <dl
              v-if="summary.distance"
              class="row mb-1"
            >
              <dt class="col-sm-6 text-sm-right">
                {{ $t('order.view.tabs.summary.statistics.distance-for-billing') }}
              </dt>
              <dd class="col-sm-6">
                {{ summary.distanceDuringRealisation }} km
              </dd>
            </dl>

            <div v-if="hasAtLeastOneRole(me.roles, ['ROLE_FINANCE_MANAGER'])">
              <dl
                v-if="summary.distanceIncome"
                class="row mb-25"
              >
                <dt class="col-sm-6 text-sm-right">
                  {{ $t('order.view.tabs.summary.incomes.distance') }}
                </dt>
                <dd class="col-sm-6">
                  {{ formatNumber(summary.distanceIncome) }} {{ $t('shared.currency-symbol') }}
                </dd>
              </dl>
              <dl
                v-if="summary.timeIncome"
                class="row mb-25"
              >
                <dt class="col-sm-6 text-sm-right">
                  {{ $t('order.view.tabs.summary.incomes.time') }}
                </dt>
                <dd class="col-sm-6">
                  {{ formatNumber(summary.timeIncome) }} {{ $t('shared.currency-symbol') }}
                </dd>
              </dl>
              <dl
                v-if="summary.fixedFeeIncome"
                class="row mb-25"
              >
                <dt class="col-sm-6 text-sm-right">
                  {{ $t('order.view.tabs.summary.incomes.fixed-fee') }}
                </dt>
                <dd class="col-sm-6">
                  {{ formatNumber(summary.fixedFeeIncome) }} {{ $t('shared.currency-symbol') }}
                </dd>
              </dl>
              <dl
                v-if="summary.surchargeIncome && !showAddSurchargeIncomeForm"
                class="row mb-25"
              >
                <dt class="col-sm-6 text-sm-right">
                  {{ summary.surchargeIncomeComment }}
                </dt>
                <dd class="col-sm-6">
                  {{ formatNumber(summary.surchargeIncome) }} {{ $t('shared.currency-symbol') }}
                </dd>
              </dl>
              <dl
                v-if="showAddSurchargeIncomeForm"
                class="row mb-25"
              >
                <dt class="col-sm-6 text-sm-right">
                  {{ $t('order.view.tabs.summary.incomes.surcharge') }}
                </dt>
                <dd class="col-sm-6">
                  <b-input-group :append="$t('shared.currency-symbol') ">
                    <b-form-input
                      v-model="summary.surchargeIncome"
                      maxlength="2"
                      type="number"
                    />
                  </b-input-group>
                </dd>
              </dl>
              <dl
                v-if="showAddSurchargeIncomeForm"
                class="row mb-25"
              >
                <dt class="col-sm-6 text-sm-right">
                  {{ $t('order.view.tabs.summary.incomes.surcharge-comment') }}
                </dt>
                <dd class="col-sm-6">
                  <b-form-input
                    v-model="summary.surchargeIncomeComment"
                    maxlength="250"
                    type="text"
                    :placeholder="$t('order.view.tabs.summary.incomes.surcharge-comment')"
                  />
                </dd>
              </dl>
              <div
                v-else-if="!showAddAdditionalCostsForm && summary.id"
                class="text-center"
              >
                <b-button
                  variant="flat-primary"
                  @click="showAddSurchargeIncomeForm = true"
                >
                  {{ $t('order.view.tabs.summary.incomes.surcharge-add') }}
                </b-button>
              </div>
            </div>
          </rc-overlay>

          <template
            v-if="hasAtLeastOneRole(me.roles, ['ROLE_FINANCE_MANAGER'])"
            #footer
          >
            <dl
              v-if="hasAtLeastOneRole(me.roles, ['ROLE_FINANCE_MANAGER']) && summary.totalIncomes"
              class="row font-medium-3 mb-0"
            >
              <dt class="col-sm-6 text-sm-right">
                {{ $t('order.view.tabs.summary.costs.total') }}
              </dt>
              <dd class="col-sm-6 font-weight-bold">
                {{ formatNumber(summary.totalIncomes) }} {{ $t('shared.currency-symbol') }}
              </dd>
            </dl>
          </template>
        </b-card>
      </b-col>

      <b-col md="12">
        <b-card>
          <div class="float-left">
            <i v-if="summary.updatedAt">{{ $t('order.view.tabs.summary.calculation-time') }}
              {{ transformDatetimeToHumanReadablePretty(summary.updatedAt) }}</i>
          </div>
          <div class="float-right">
            <b-button
              v-if="hasAtLeastOneRole(me.roles, ['ROLE_FINANCE_MANAGER']) && !loading"
              class="mx-25"
              variant="outline-primary"
              @click="requestRecalculation()"
            >
              {{ $t('order.view.tabs.summary.request-recalculation') }}
            </b-button>
            <b-button
              v-if="hasAtLeastOneRole(me.roles, ['ROLE_FINANCE_MANAGER']) && !loading && (showAddSurchargeIncomeForm || showAddAdditionalCostsForm)"
              class="mx-25"
              variant="primary"
              @click="submit"
            >
              {{ $t('shared.button.submit') }}
            </b-button>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>
<script>

import {
  cloneNested, empty, parseRequestError, transformDatetimeToHumanReadablePretty,
} from '@/helpers/helpers'
import {
  BAvatar, BButton, BCard, BCol, BFormInput, BInputGroup, BLink, BRow,
} from 'bootstrap-vue'
import RcOverlay from '@/layouts/components/rc/RcOverlay.vue'
import _ from 'lodash'
import guard from '@/guard'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BAvatar,
    BButton,
    BLink,
    BFormInput,
    BInputGroup,

    RcOverlay,
  },
  data() {
    const summaryModel = {
      id: null,
      additionalCosts: 0,
      additionalCostsComment: '',
      distance: 0,
      distanceDuringRealisation: 0,
      realisationTimeInMinutes: 0,
      realisationTimeForBillingInMinutes: 0,
      distanceCost: 0,
      distanceIncome: 0,
      driverCost: 0,
      doctorCost: 0,
      fixedFeeIncome: 0,
      paramedicCost: 0,
      timeIncome: 0,
      totalCosts: 0,
      surchargeIncome: 0,
      surchargeIncomeComment: '',
      totalIncomes: 0,
      createdAt: '',
      updatedAt: '',
    }

    return {
      loading: false,
      summaryModel: cloneNested(summaryModel),
      summary: cloneNested(summaryModel),
      showAddAdditionalCostsForm: false,
      showAddSurchargeIncomeForm: false,
    }
  },
  computed: {
    order() {
      return this.$store.getters['order/getOrderData']
    },
    balance() {
      return this.formatNumber(this.summary.totalIncomes - this.summary.totalCosts)
    },
    me() {
      return cloneNested(this.$store.getters['auth/getUser'])
    },
  },
  beforeMount() {
    this.init()
  },
  methods: {
    hasAtLeastOneRole: guard.hasAtLeastOneRole,
    transformDatetimeToHumanReadablePretty,
    empty,
    init() {
      this.loading = true
      this.$store.dispatch('order/fetchSummaryByOrderId', this.$route.params.id)
        .then(res => {
          this.summary = _.merge(cloneNested(this.summaryModel), res.data)
        })
        .catch(err => parseRequestError(err))
        .finally(() => {
          this.loading = false
        })
    },
    requestRecalculation() {
      this.$store.dispatch('order/requestOrderSummaryRecalculation', this.$route.params.id)
        .then(() => {
          this.init()
          window.toast.notify.success(this.$t('order.view.tabs.summary.recalculated'))
        })
        .catch(err => parseRequestError(err))
        .finally(() => {
          this.loading = false
        })
    },
    submit() {
      this.loading = true

      const payload = {
        id: this.summary.id,
        data: this.summary,
      }

      this.$store.dispatch('order/updateSummary', payload)
        .then(() => {
          window.toast.notify.success(this.$t('order.notify.edit.success_title'))
          this.showAddSurchargeIncomeForm = false
          this.showAddAdditionalCostsForm = false
        })
        .catch(err => {
          parseRequestError(err)
        })
        .finally(() => {
          this.loading = false
        })
    },
    formatNumber(num) {
      return Number(num).toFixed(2)
    },
  },
}
</script>
