<template>
  <b-row class="match-height">
    <b-col cols="12">

      <validation-observer
        ref="refAddOrderAttachmentFormObserver"
      >
        <b-form
          ref="refKnowledgeChecklistForm"
          @submit.prevent="onSubmit"
        >
          <b-card :title="$t('order.view.tabs.patient-info.attachments.add.title')">

            <validation-provider
              #default="validationContext"
              :name="$t('order.view.tabs.patient-info.attachments.label.files')"
              rules="required|size:102400"
            >
              <b-form-group
                :label="$t('order.view.tabs.patient-info.attachments.label.files')"
                label-for="attachment-file-file"
              >
                <b-form-file
                  v-model="attachment.files"
                  :placeholder="$t('order.view.tabs.patient-info.attachments.add.select-or-drop')"
                  :drop-placeholder="$t('order.view.tabs.patient-info.attachments.add.drop')"
                  multiple
                >
                  <template
                    slot="file-name"
                    slot-scope="{ names }"
                  >
                    <b-badge variant="primary">
                      {{ names[0] }}
                    </b-badge>
                    <b-badge
                      v-if="names.length > 1"
                      variant="dark"
                      class="ml-1"
                    >
                      {{ $tc('order.view.tabs.patient-info.attachments.add.additional-files', names.length - 1, {n: names.length - 1}) }}
                    </b-badge>
                  </template>
                </b-form-file>
                <small class="text-danger">
                  {{ validationContext.errors[0] }}
                </small>
              </b-form-group>
            </validation-provider>

            <b-form-group
              :label="$t('order.view.tabs.patient-info.attachments.label.category')"
              label-for="attachment-file-file"
              class="mt-50"
            >
              <b-form-select
                v-model="attachment.category"
                :options="attachmentCategories"
              />
            </b-form-group>

            <div class="float-right mt-2">
              <b-button
                variant="primary"
                type="submit"
                :disabled="uploadingFile"
              >
                <feather-icon
                  icon="PlusIcon"
                />
                {{ $t('shared.button.add') }}
              </b-button>

            </div>

          </b-card>
        </b-form>
      </validation-observer>
    </b-col>

    <b-col
      v-if="attachments.length"
      cols="12"
    >
      <b-card no-body>

        <h4 class="pt-2 pl-2 pb-1">
          {{ $t('order.view.tabs.patient-info.attachments.list.title') }}
        </h4>
        <b-table
          ref="refReportsTable"
          class="position-relative"
          :items="attachments"
          responsive
          :fields="tableColumns"
          primary-key="id"
          show-empty
        >

          <!-- Column: category -->
          <template #cell(category)="data">
            {{ $t(`order.view.tabs.patient-info.attachments.add.categories.${data.item.category}`) }}
          </template>

          <!-- Column: createdAt -->
          <template #cell(createdAt)="data">
            {{ transformDatetimeToHumanReadablePretty(data.item.createdAt) }}
          </template>

          <!-- Column: Actions -->
          <template #cell(actions)="data">

            <div class="text-nowrap">
              <feather-icon
                v-if="downloadings.includes(data.item.id)"
                icon="LoaderIcon"
                size="16"
              />
              <span v-else>
                <feather-icon
                  icon="DownloadIcon"
                  class="cursor-pointer"
                  size="16"
                  @click="download(data.item.id)"
                />
                <feather-icon
                  icon="TrashIcon"
                  class="cursor-pointer text-danger ml-1"
                  size="16"
                  @click="confirmRemoveAttachment(data.item.id)"
                />
              </span>
            </div>

          </template>
        </b-table>

      </b-card>
    </b-col>

  </b-row>
</template>
<script>

import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { parseRequestError, transformDatetimeToHumanReadablePretty } from '@/helpers/helpers'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    order: {
      type: Object,
      default: () => {},
    },
    commitOrder: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      attachments: [],
      uploadingProgress: null,
      uploadingFile: false,
      downloadings: [],
      attachment: {
        category: 'external_order_document',
        files: [],
      },
      tableColumns: [
        {
          label: this.$t('order.view.tabs.patient-info.attachments.label.category'),
          key: 'category',
          sortable: true,
        },
        {
          label: this.$t('order.view.tabs.patient-info.attachments.list.createdAt'),
          key: 'createdAt',
          sortable: true,
        },
        {
          label: this.$t('shared.actions'),
          key: 'actions',
          value: null,
          class: 'actions-column',
        },
      ],
    }
  },
  computed: {
    attachmentCategories() {
      return [
        {
          value: 'external_order_document',
          text: this.$t('order.view.tabs.patient-info.attachments.add.categories.external_order_document'),
        },
        {
          value: 'undefined',
          text: this.$t('order.view.tabs.patient-info.attachments.add.categories.undefined'),
        },
      ]
    },
  },
  beforeMount() {
    this.$store.dispatch('attachment/fetchAttachments', this.$route.params.id)
      .then(attachments => {
        this.attachments = attachments.data
      })
  },
  methods: {
    transformDatetimeToHumanReadablePretty,
    onSubmit() {
      this.$refs.refAddOrderAttachmentFormObserver.validate()
        .then(valid => {
          if (!valid) {
            return false
          }

          this.uploadingFile = true
          const payload = new FormData()
          this.attachment.files.forEach(file => {
            payload.append('file[]', file, file.filename)
          })
          payload.append('category', this.attachment.category)

          this.$store.dispatch(
            'attachment/addAttachments',
            {
              payload,
              orderId: this.order.id,
              progressCallback: progressEvent => {
                this.uploadingProgress = Math.round((progressEvent.loaded * 100) / progressEvent.total)
              },
            },
          )
            .then(attachments => {
              this.attachments = this.attachments.concat(attachments.data)
              this.attachment.files = []
              this.$refs.refAddOrderAttachmentFormObserver.reset()
            })
            .finally(() => {
              this.uploadingFile = false
            })

          return true
        })
    },
    confirmRemoveAttachment(id) {
      this.loading = true

      this.$bvModal
        .msgBoxConfirm(
          this.$t('order.view.tabs.patient-info.attachments.remove.description'),
          {
            title: this.$t('order.view.tabs.patient-info.attachments.remove.title'),
            okTitle: this.$t('shared.yes'),
            cancelTitle: this.$t('shared.no'),
            okVariant: 'danger',
            cancelVariant: 'outline-secondary',
          },
        )
        .then(answer => {
          if (answer) {
            this.$store.dispatch(
              'attachment/deleteAttachment',
              id,
            )
              .then(() => {
                this.$store.dispatch('attachment/fetchAttachments', this.$route.params.id)
                  .then(attachments => {
                    this.attachments = attachments.data
                  })
              })
          } else {
            this.loading = false
          }
        })
    },
    download(id) {
      this.downloadings.push(id)
      this.$store.dispatch('attachment/downloadAttachmentFile', id)
        .then(response => {
          const contentDisposition = response.headers['content-disposition']
          const contentType = response.headers['content-type']
          const match = contentDisposition.match(/filename\s*=(.+)/i)
          const filename = match[1]

          const blob = new Blob([response.data], { type: contentType })
          const link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob)
          link.setAttribute('download', filename)
          link.click()
        })
        .catch(err => {
          parseRequestError(err)
        })
        .finally(() => {
          const index = this.downloadings.indexOf(id)
          if (index !== -1) {
            this.downloadings.splice(index, 1)
          }
        })
    },
  },
}
</script>
