<template>
  <div>
    <b-row class="match-height">
      <b-col md="6">
        <b-card :title="$t('order.view.tabs.patient-info.patient-data.title')">
          <div>

            <div class="w-full">
              <label>
                {{ $t('order.patient-info.pesel') }}
              </label>
              <b-form-input
                v-model="order.patientInfo.pesel"
                type="number"
                :name="$t('order.patient-info.pesel')"
                :disabled="order.patientInfo.patientData.noPesel"
                @change="commitOrder"
              />
            </div>

            <div class="w-full">
              <b-form-checkbox
                v-model="order.patientInfo.patientData.noPesel"
                class="my-1"
                @change="order.patientInfo.pesel = null; commitOrder()"
              >
                {{ $t('order.patient-info.no-pesel') }}
              </b-form-checkbox>
            </div>

          </div>
        </b-card>
      </b-col>

      <b-col md="6">
        <b-card :title="$t('order.patient-info.transport-status.title')">
          <b-form-radio-group
            v-model="order.patientInfo.patientData.transportStatus"
            stacked
            @change="commitOrder"
          >
            <b-form-radio
              class="my-25"
              value="patientRemained"
            >
              {{ $t('order.patient-info.transport-status.patientRemained') }}
            </b-form-radio>

            <b-form-radio
              class="my-25"
              value="patientTransported"
            >
              {{ $t('order.patient-info.transport-status.patientTransported') }}
            </b-form-radio>

            <b-form-radio
              class="my-25"
              value="patientAbsent"
            >
              {{ $t('order.patient-info.transport-status.patientAbsent') }}
            </b-form-radio>

            <b-form-radio
              class="my-25"
              value="patientRefused"
            >
              {{ $t('order.patient-info.transport-status.patientRefused') }}
            </b-form-radio>
          </b-form-radio-group>

        </b-card>
      </b-col>

      <b-col>
        <b-card :title="$t('order.patient-info.valuables.title')">

          <b-form-checkbox
            v-model="order.patientInfo.patientData.valuables.identityDocument"
            class="my-50"
            @change="commitOrder"
          >
            {{ $t('order.patient-info.valuables.identity-document') }}
          </b-form-checkbox>

          <b-form-checkbox
            v-model="order.patientInfo.patientData.valuables.other"
            class="my-50"
            @change="order.patientInfo.patientData.valuables.otherItems = null; commitOrder()"
          >
            {{ $t('order.patient-info.valuables.other') }}
          </b-form-checkbox>

          <label>
            {{ $t('order.patient-info.valuables.other-items') }}
          </label>
          <b-form-textarea
            v-model="order.patientInfo.patientData.valuables.otherItems"
            :name="$t('order.patient-info.valuables.other-items')"
            :disabled="!order.patientInfo.patientData.valuables.other"
            @change="commitOrder"
          />

        </b-card>
      </b-col>
    </b-row>
  </div>
</template>
<script>

import {
  BCard, BCol, BFormCheckbox, BFormInput, BFormRadio, BFormRadioGroup, BFormTextarea, BRow,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BFormInput,
    BFormRadio,
    BFormRadioGroup,
    BFormCheckbox,
    BFormTextarea,
  },
  props: {
    order: {
      type: Object,
      default: () => {},
    },
    commitOrder: {
      type: Function,
      default: () => {},
    },
  },
}
</script>
